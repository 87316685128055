import React from "react";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'

const SelectField = (props) => {
  const viewOnlyFlag = useSelector((state) => state.formSubmit.viewOnlyFlag);
  const defaultFun = (e) => { }
  const { name } = props.register;
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const isDisable = props.disable || viewOnlyFlag || false;
  const onChangeCallback = props.onChange || defaultFun
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid rgba(255, 133, 133, 0.37)",
      backgroundColor: "#FFF8F8",
    }),
  };

  return (
    <>
      <Controller
        control={props.control}
        name={name}
        render={({ field, fieldState }) => {
          const error = fieldState.error !== undefined;
          return (
            <>
              <label
                htmlFor={name}
                className={`form-label ${error && "text-danger"}`}
              >
                {props?.label} {required && <span className="red">*</span>}
              </label>
              {props?.creatable ? <><CreatableSelect
                isDisabled={isDisable}
                styles={required && !field.value && customStyles}
                className={` ${required && !field.value && "is-warning"} ${error && "is-invalid"
                  }`}
                {...field}
                options={props?.options ? props?.options : []}
              />
                <div id={name + 'btn'} className="form-text">Please select from the drop-down or type to create other option.</div>
              </>
                : <Select

                  isDisabled={isDisable}
                  styles={required && !field.value && customStyles}
                  className={` ${required && !field.value && "is-warning"} ${error && "is-invalid"
                    }`}
                  {...field}

                  onChange={(e) => {
                    field.onChange(e);
                    onChangeCallback(e)
                  }}
                  options={props?.options ? props?.options : []}
                />
              }

              <div className="invalid-feedback" style={{ display: "block" }}>
                {error && fieldState.error?.value?.message || fieldState.error?.message}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default SelectField;
