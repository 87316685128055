import { combineReducers } from "@reduxjs/toolkit";
import loginSlice from "./logindSlice";
import registrationSlice from "./registrationSlice";
import formInputCacheSlice from './formInputCacheSlice';

export default combineReducers({
  loginSlice,
  register:registrationSlice,
  inputSlice:formInputCacheSlice
});
