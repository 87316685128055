import React, { useCallback, useState } from "react";
import Webcam from "react-webcam";
import { MdOutlineFlipCameraIos } from "react-icons/md";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Avatar from "@mui/material/Avatar";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Camera from "@mui/icons-material/Camera";
import { useMobileScanSaveMutation } from "../../services/identificationApi";
import "./style.css";
import {
  CircularProgress,
  Container,
  LinearProgress,
  Stack,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Cropper from "react-easy-crop";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};
const videoEnvironment = {
  width: 1280,
  height: 720,
  facingMode: "environment",
};
const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: "0 auto",
  border: "8px solid #fff",
});
const CameraScan = () => {
  const webcamRef = React.useRef(null);
  const [Save, Result] = useMobileScanSaveMutation();
  const navigate = useNavigate();
  const [cameraFace, setCameraFace] = useState(1);
  let { id, docId } = useParams();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
  }, []);

  if (Result.isSuccess)
    if (Result.data?.status == 200) {
      navigate(`/scan/${id}/back/${docId}`);
    } else {
      alert("Fail to save, try again");
    }

  if (Result.isError) {
    alert("Fail to save, try again");
  }

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log(
      "🚀 ~ file: CameraScan.js ~ line 78 ~ capture ~ imageSrc",
      webcamRef
    );
    Save({
      file: imageSrc,
      document_id: docId,
      trn_id: id,
      data: {
        OCRData: null,
        raw_data: null,
      },
    }).then((res) => {
      console.log("Check Response for camera DL save", res);
      if (res?.data?.status == 200) {
        if (docId !== "2") return navigate(`/scan/${id}/success`);
        navigate(`/scan/${id}/back/${docId}`);
      } else {
        alert("Fail to save, try again");
      }
    });
  }, [webcamRef]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <ArrowBack />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Capture Front Side
            </Typography>
          </Toolbar>
        </AppBar>
        {Result.isLoading && <LinearProgress />}
      </Box>
      <Container maxWidth="sm">
        <Box sx={{ bgcolor: "#cfe8fc", height: "91vh" }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <div style={{ padding: "10px" }}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/png"
                width={"100%"}
                videoConstraints={
                  !cameraFace ? videoConstraints : videoEnvironment
                }
              />
              <Typography
                variant="p"
                component="div"
                sx={{ flexGrow: 1, padding: "10px" }}
              >
                Please capture the front of your ID
              </Typography>
            </div>
          </Stack>
        </Box>
      </Container>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          top: "auto",
          bottom: 0,
          backgroundColor: "rgba(245, 245, 245, 0.9)",
        }}
      >
        <Toolbar>
          <IconButton
            onClick={() => {
              setCameraFace(!cameraFace);
            }}
            sx={{ backgroundColor: "#fff" }}
            aria-label="open drawer"
          >
            <CameraswitchIcon />
          </IconButton>
          <StyledFab
            disabled={Result.isLoading}
            onClick={() => {
              capture();
            }}
            sx={{ backgroundColor: "rgba(245, 245, 245, 0.4)" }}
            aria-label="add"
          >
            <Camera />
          </StyledFab>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default CameraScan;
