/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { useSelector } from "react-redux";
import {
  useGetIncomeBankingQuery,
  useGetSectionStatusQuery,
} from "../../services/identificationApi";
import Paystub_Source from "./Paystub_Source";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const ProofOfIncome_doc_upload = () => {
  const [employmentIncomeProof, setEmploymentIncomeProof] =
    React.useState(false);
  const [displayContent, setDisplayContent] = React.useState(0);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { isLoading, isSuccess, isError, data } =
    useGetIncomeBankingQuery(trn_id);

  return (
    <div className="ei-wrap white-box">
      <div className="ei-body">
        <div className="row">
          <div className="col-12">
            <h6>Proof of Employment Income</h6>
          </div>
        </div>
        <div className="row">
          <>
            {data?.employment_details?.employer.map((field, index) => {
              return (
                <>
                  <Paystub_Source
                    onlyPdf={true}
                    key={field.id}
                    documentId={"3"}
                    field={field}
                    index={index}
                    paystub={true}
                    title={"Proof of Employment Income"}
                    setDialogAction={setEmploymentIncomeProof}
                    warningText={
                      "IMPORTANT! PDF Earnings Statements only! Click (i) button above for full details"
                    }
                  />
                </>
              );
            })}
            {data?.employment_details?.employer?.length === 0 && (
              <Paystub_Source
                onlyPdf={true}
                documentId={"3"}
                paystub={true}
                title={"Proof of Employment Income"}
                setDialogAction={setEmploymentIncomeProof}
              />
            )}
          </>
        </div>
        <Dialog
          open={employmentIncomeProof}
          onClose={() => {
            setDisplayContent(0);
            setEmploymentIncomeProof(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <p>
                <strong>IMPORTANT!</strong> In order to calculate and verify
                your employment income, if you are currently employed, we will
                need you to upload PDF’s of your earnings statements (paystubs)
                downloaded directly from your payroll website. Please do NOT
                upload pictures or scanned images, and please do NOT “print to
                PDF”, “save as” or otherwise modify your PDF documents in any
                way as doing so can render your documents unverifiable. If you
                have questions, you can find answers to frequently-asked
                questions below by clicking the relevant question in blue.
              </p>
              <small
                className="app-blue-color cursor-pointer"
                onClick={() => setDisplayContent(1)}
              >
                Why original PDF’s?
              </small>
              <small className={`mb-2 ${displayContent === 1 ? "" : "d-none"}`}>
                <br />
                The Docuverus software directly imports original PDF documents,
                which allows our system to more accurately read and validate
                your documents, expedites processing of your application, and
                can help to qualify you more quickly.
              </small>
              <br />
              <div style={{ height: "5px" }} />
              <small
                className="app-blue-color cursor-pointer"
                onClick={() => setDisplayContent(2)}
              >
                Newly employed?
              </small>
              <small className={`mb-2 ${displayContent === 2 ? "" : "d-none"}`}>
                <br />
                If you are starting a new job and do not yet have access to
                original PDF’s of your earnings statements, then please check
                the box to the below, which will allow you to upload a PDF of
                your offer letter.
              </small>
              <br />
              <div style={{ height: "5px" }} />

              <small
                className="app-blue-color cursor-pointer"
                onClick={() => setDisplayContent(3)}
              >
                Self-employed?
              </small>
              <small className={`mb-2 ${displayContent === 3 ? "" : "d-none"}`}>
                <br />
                If you are self-employed and do not receive earnings statements
                / paystubs, then in order to verify your income, we will need
                for you to upload original PDF’s of your 3 most recent personal
                bank statements downloaded directly from your online bank
                account, which you can upload under the banking section below.
              </small>
              <br />
              <div style={{ height: "5px" }} />

              {/* <small
                className="app-blue-color cursor-pointer"
                onClick={() => setDisplayContent(4)}
              >
                Don’t have access to original PDF’s?
              </small> */}
              {/* <small className={`mb-2 ${displayContent === 4 ? "" : "d-none"}`}>
                <br />
                If you do not have access to original PDF’s of your earnings
                statements / paystubs, then check the box below to certify this.
                You can then upload image documents of your earnings statements
                in the document upload box below.
              </small>
              <br /> */}
              <div style={{ height: "5px" }} />

              <small
                className="app-blue-color cursor-pointer"
                onClick={() => setDisplayContent(5)}
              >
                Have non-employment income?
              </small>
              <small className={`mb-2 ${displayContent === 5 ? "" : "d-none"}`}>
                <br />
                If you wish to upload proof of non-employment income, you can
                upload relevant documents in the “Proof of Non-Employment
                Income” section below.
              </small>
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEmploymentIncomeProof(false);
                setDisplayContent(0);
              }}
              autoFocus
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
  return <></>;
};

export default ProofOfIncome_doc_upload;
