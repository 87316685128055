import React from "react";
import { useFormContext, useController } from "react-hook-form";

const InputTextArea = (props) => {
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { name } = props.register;
  const { control, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  //    const error = errors[name];
  return (
    <>
      <label
        htmlFor={name}
        className={`form-label ${error !== undefined && "text-danger"}`}
      >
        {props.label} {required && <span className="red">*</span>}
      </label>
      <textarea
        name={name}
        className={`form-control cus-input-control ${
          required && !field.value && "is-warning"
        } ${error !== undefined && "is-invalid"}`}
        rows="3"
        {...field}
        placeholder={props.placeholder || `Enter ${props.label}`}
      ></textarea>
      <em className="text-limit ml-auto d-block text-right">
        Max 500 Characters
      </em>
      <div className="invalid-feedback">
        {error !== undefined && error.message}
      </div>
    </>
  );
};

export default InputTextArea;
