import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import GoogleAutoComplete from "../../components/common/googleAutocomplete/googleAutocomplete";
import InputCurrencyField from "../../components/registration/Inputs/InputCurrencyField";
import InputDateField from "../../components/registration/Inputs/InputDateField";
import InputField from "../../components/registration/Inputs/InputField";
import SelectField from "../../components/registration/Inputs/SelectField";
import * as yup from "yup";
import {
  useGetUpdatedDesiredAddressQuery,
  useGetUpdatedFloorsAndUnitQuery,
  useUpdateDesiredAddessMutation,
  useUpdateFloorPlanUnitMutation,
} from "../../services/identificationApi";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";

function UpdateDesireAddressProperty(props) {
  console.log("UpdateDesireAddressProperty Props:19", props);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );

  const { data, isLoading, isError } = useGetUpdatedDesiredAddressQuery(trn_id);
  console.log("Check Updated Desired Address Data", data);

  const getUpdatedFloorsAndUnit = useGetUpdatedFloorsAndUnitQuery(trn_id);
  console.log("Check Updated Floor and Unit", getUpdatedFloorsAndUnit?.data);

  const [updateFloorPlanUnit] = useUpdateFloorPlanUnitMutation();

  const schema = yup.object().shape({
    // address: yup.string().required("required"),
    // city: yup.string().required("required"),
    // state: yup.object({
    //   value: yup.string().required("required"),
    //   label: yup.string(),
    // }),
    // unitNo: yup
    //   .string()
    //   .nullable()
    //   .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed"),
    // rent: yup
    //   .string()
    //   .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
    //   .nullable(true),
    // desiredMoveInDate: yup.date().typeError("required").required("required"),
    // floor: yup.object({
    //   value: yup.string().required("required"),
    //   label: yup.string(),
    // }),
    // Unit: yup.object({
    //   value: yup.string().required("required"),
    //   label: yup.string(),
    // }),
  });

  const methods = useForm({
    defaultValues: {
      // address: "",
      // city: "",
      // state: "",
      // unitNo: "",
      // rent: "",
      // desiredMoveInDate: "",
      floor: "",
      Unit: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { register, setValue, watch, getValues } = methods;

  const onSubmit = async (formData) => {
    console.log("Form DAta", formData, trn_id);
    let payload = {
      trn_id: trn_id,
      floorId: formData?.floor?.value,
      unitId: formData?.Unit?.value,
    };
    const response = await toast.promise(updateFloorPlanUnit(payload), {
      pending: "Saving In Progress",
      success: "Floor Plan Unit updated 👌",
      error: "Saving failed 🤯",
    });
    if (response?.data?.success) {
    } else {
      toast.error("Error in update address");
    }
  };

  React.useEffect(() => {
    if (data) {
      let state = {
        value: data?.records?.applicantState[0]?.ID,
        label:
          data?.records?.applicantState[0]?.StateShortCode +
          " - " +
          data?.records?.applicantState[0]?.StateName,
      };
      let api_data = data?.records?.result?.recordset[0];
      setValue("address", api_data.DesiredPropertyAddress);
      setValue("city", api_data.City);
      setValue("state", state);
      setValue("rent", api_data.DesiredRent);
      setValue("desiredMoveInDate", api_data.DesiredMoveInDate);
      setValue("unitNo", api_data.DesiredUnitNumber);
    }
  }, [data, setValue]);

  const getUnitsList = (floorId) => {
    console.log("Check unit filter", floorId);
    if (!floorId) return [];
    const unitsFilter = props?.units.filter((item) => {
      return item.FloorPlanID === floorId;
    });

    const units = unitsFilter.map((item, index) => {
      return {
        value: item.ID || index,
        label: item.UnitName,
      };
    });

    if (units.length) return units;
    return [];
  };

  React.useEffect(() => {
    const subscribe = watch((value, { name, type }) => {
      if (name === "floor" && type === "change") {
        setValue("Unit", "");
      }
    });
    return () => subscribe.unsubscribe();
  }, [setValue, watch]);

  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  else
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="position-relative" style={{ height: "70vh" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 offset-md-2 ">
                  <div
                    className="form-w-block white-box mr-b30"
                    id="desired"
                    onFocus={() => {
                      //   setNav(2);
                    }}
                  >
                    <div className="row form-inputs">
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group cus-input-group mr-b15">
                          <SelectField
                            // defaultValue={props?.floors[1]}
                            notRequired={true}
                            options={props?.floors ? props?.floors : []}
                            label="By Floor Plan"
                            register={register("floor")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <div className="form-group cus-input-group mr-b15">
                          <SelectField
                            notRequired={true}
                            options={getUnitsList(watch("floor")?.value)}
                            label="By Unit Plan"
                            register={register("Unit")}
                          />
                        </div>
                      </div>
                      <div className="f-a-btn text-center">
                        <button
                          type="submit"
                          className="btn btn-info text-light "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </form>
      </FormProvider>
    );
}

export default UpdateDesireAddressProperty;
