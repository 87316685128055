import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import InputCheckBoxField from "../../../../registration/Inputs/InputCheckBoxField";
import InputField from "../../../../registration/Inputs/InputField";
import InputFieldPhone from "../../../../registration/Inputs/InputFieldPhone";
import { TiDelete } from "react-icons/ti";

const CoSigner = (props) => {
  const { register } = props;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "is_co_signer.fields", // unique name for your Field Array
    keyName: "co_signer_id",
    // keyName: "id", default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <>

      {fields?.map((field, index) => (
        <div key={field.co_signer_id}>
          <div className="blue-box pd15 mr-t20">
            <div className="row">
              <div className="col-sm-12 mb-2">
                <span className="s-no-index float-left">{index + 1}</span>{" "}
                {!field?.is_fixed && (<button
                  type="button"
                  disabled={field.is_submitted}
                  onClick={async () => {
                    remove(index);
                    props?.checkSourceAvailableHandler("cosigner");
                  }}
                  title="Delete this Employer"
                  className="float-right btn btn-sm btn-danger"
                >
                  <TiDelete size={25} /> Delete
                </button>)}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  disable={field?.is_fixed}
                  label={`First Name`}
                  register={register(`is_co_signer.fields.${index}.first_name`)}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  disable={field?.is_fixed}
                  label={`Last Name`}
                  register={register(`is_co_signer.fields.${index}.last_name`)}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputField
                  disable={field?.is_fixed}
                  label={`Email`}
                  register={register(`is_co_signer.fields.${index}.email`)}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4">
                <InputFieldPhone
                  disable={field?.is_fixed}
                  label={`Mobile Number`}
                  register={register(`is_co_signer.fields.${index}.mobile`)}
                  notRequired={true}
                />
              </div>

              {errors && errors[index] && errors[index].number}
            </div>
            {index !== 0 && (
              <div className="add-text-btn  flex1 text-right ps-2 pt-3">
                <button type="button" onClick={(event) => remove(index)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="blue-box pd15">
        <div className="add-text-btn flex1 text-center ps-2 pt-3">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another Guarantor / Co-signer
          </button>
        </div>
      </div>
    </>
  );
};

export default CoSigner;
