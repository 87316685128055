import React, { forwardRef } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import "./index.scss";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import InputMask from "react-input-mask";

const ExampleCustomInput = forwardRef((inputFun, ref) => (
  <InputMask mask={"99/99/9999"} placeholder="mm/dd/yyyy" {...inputFun} inputRef={ref} />
));

const InputDateField = (props) => {
  const { name } = props.register;
  const { control, setValue } = useFormContext();
  const required = props.notRequired === undefined ? true : !props.notRequired;
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const error = fieldState.error !== undefined;
          const { onChange, value, ref, ...rest } = field;
          return (
            <>
              <label
                htmlFor={name}
                className={`form-label ${error && "text-danger"}`}
              >
                {props.label} {required && <span className="red">*</span>}
              </label>
              <ReactDatePicker
                ref={(elem) => {
                  elem && ref(elem.input);
                }}
                maxDate={props.maxDate}
                minDate={props.minDate}
                placeholderText='MM/DD/YYYY'
                onChange={(date) => {
                  field.onChange(date)
                  //moment.utc(date).format('MM/DD/YYYY')
                }}
                selected={field.value ? moment(field.value).toDate() : ''}
                showYearDropdown
                dateFormat="MM/dd/yyyy"
                className={`form-control cus-input-control ${required && !field.value && "is-warning"
                  } ${error && "is-invalid"}`}
                customInput={<ExampleCustomInput />}
                timeZone=""
              />
              <div id={name + 'btn'} className="form-text">Please enter date in MM/DD/YYYY format.</div>
              
              <div id={name + 'btn'} className="invalid-feedback">
                {error && fieldState?.error?.message}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default InputDateField;
