/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { useSelector } from "react-redux";
import {
  useGetIncomeBankingQuery,
  useGetSectionStatusQuery,
} from "../../services/identificationApi";
import Paystub_Source from "./Paystub_Source";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

const ProofOfOtherIncomeDocUpload = () => {
  const [otherIncomeProof, setOtherIncomeProof] = React.useState(false);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { isLoading, isSuccess, isError, data } =
    useGetIncomeBankingQuery(trn_id);
  console.log("NON-EMP:15", data);
  return (
    <>
      {true && (
        <div className="ei-wrap white-box">
          <div className="ei-body">
            <div className="row">
              <div className="col-12">
                <h6>Proof of Non-Employment Income</h6>
              </div>
            </div>
            <div className="row">
              <>
                {data?.other_income_details?.otherincome.map((field, index) => (
                  <Paystub_Source
                    key={field.id}
                    field={field}
                    index={index}
                    documentId={"7"}
                    title={"Proof of Non-Employment Income"}
                    setDialogAction={setOtherIncomeProof}
                    warningText={
                      "IMPORTANT! PDF proof of income can be uploaded here. Please refrain from uploading pictures of documents. PDF bank statements downloaded directly from bank account website should also be uploaded in the Banking section below to prove non-employment income."
                    }
                  />
                ))}
                {data?.other_income_details?.otherincome?.length === 0 && (
                  <Paystub_Source
                    documentId={"7"}
                    title={"Proof of Non-Employment Income"}
                    setDialogAction={setOtherIncomeProof}
                  />
                )}
              </>
            </div>
            <Dialog
              open={otherIncomeProof}
              onClose={() => {
                setOtherIncomeProof(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  PDF bank statements covering 2-3 most recent months downloaded directly from your bank account website are required to verify non-employment income.  Please be sure to add bank account information below and please be sure to upload PDF bank statements for each bank account entered.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    setOtherIncomeProof(false);
                  }}
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
};

export default ProofOfOtherIncomeDocUpload;
