import { createSlice } from "@reduxjs/toolkit";
/**
 * trnId is null|0|trn_id
 */
const initialState = {
  email: "",
  token: null,
  logout: false,
  urlGeneratedAts: null,
  trnId: 0,
  applicationType: null,
  newApp: false
};
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    trnId: (state, action) => {
      if (action.payload)
        state.trnId = action.payload;
        
    },
    urlGeneratedAts: (state, action) => {
      state.urlGeneratedAts = action.payload;
    },
    email: (state, action) => {
      state.email = action.payload;
    },
    token: (state, action) => {
      state.token = action.payload;
    },
    logout: (state, action) => {
      if (action.payload === true) {
        localStorage.clear();
        state.token = null;
      }
      state.logout = action.payload;
    },
    applicationType: (state, action) => {
      state.applicationType = action.payload;
    },
    newApplication: (state, action) => {
      state.newApp = action.payload;
    }
  },
});

export const { token, logout, trnId, urlGeneratedAts, applicationType, newApplication } =
  loginSlice.actions;

export default loginSlice.reducer;
