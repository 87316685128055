import React, { useState } from "react";
import Webcam from "react-webcam";
import { MdOutlineFlipCameraIos } from "react-icons/md";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import Camera from "@mui/icons-material/Camera";
import { useMobileScanSaveMutation } from '../../services/identificationApi';
import { CircularProgress, Container, LinearProgress, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
};
const videoEnvironment = {
    width: 1280,
    height: 720,
    facingMode: "environment",
};
const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
    border: "8px solid #fff"
});
const CameraScanBack = () => {

    let { id, docId } = useParams();
    const webcamRef = React.useRef(null);
    const [Save, Result] = useMobileScanSaveMutation();
    const navigate = useNavigate();
    const [cameraFace, setCameraFace] = useState(0);
    React.useEffect(() => {
        const reloadCount = sessionStorage.getItem('reloadCountBackCamera') || 0;
        if (!reloadCount) {
            sessionStorage.setItem('reloadCountBackCamera', 1);
            window.location.reload();
        }
    }, [])

    if (Result.isSuccess)
        if (Result.data?.status == 200) {
            navigate(`/scan/${id}/success`)
        } else {
            alert("Fail to save, try again")
        }

    if (Result.isError) {
        alert("Fail to save, try again")
    }

    const dateFormat = (date) => {
        return date.substring(0, 2) + '/' + date.substring(2, 2) + '/' + date.substring(4, 4);
    }

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        const licenseInfo = JSON.parse(localStorage.getItem('licenseInfo'));

        const MYOCRData = [
            {
                "alias": "License Id",
                "confidence": 89,
                "field_value_original": licenseInfo?.DAQ?.content.replace(' ', '') || '',
                "id": 33,
                "location": "",
                "name": "license_id",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "First Name",
                "confidence": 93,
                "field_value_original": licenseInfo?.DAC?.content.replace(' ', '') || '',
                "id": 2,
                "location": "",
                "name": "first_name",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "Middle Name",
                "confidence": 97,
                "field_value_original": licenseInfo?.DAD?.content.replace(' ', '') || '',
                "id": 34,
                "location": "",
                "name": "middle_name",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "Last Name",
                "confidence": 92,
                "field_value_original": licenseInfo?.DCS?.content.replace(' ', '') || '',
                "id": 3,
                "location": "",
                "name": "last_name",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "Address",
                "confidence": 71,
                "field_value_original": licenseInfo?.DAG?.content.replace(' ', '') || '',
                "id": 8,
                "location": "",
                "name": "address",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "City",
                "confidence": 92,
                "field_value_original": licenseInfo?.DAI?.content.replace(' ', '') || '',
                "id": 11,
                "location": "",
                "name": "city",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "State",
                "confidence": 92,
                "field_value_original": licenseInfo?.DAJ?.content.replace(' ', '') || '',
                "id": 1,
                "location": "",
                "name": "state",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "Postal Code",
                "confidence": 92,
                "field_value_original": licenseInfo?.DAK?.content.replace(' ', '') || '',
                "id": 9,
                "location": "",
                "name": "postal_code",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "DOB",
                "confidence": 84,
                "field_value_original": licenseInfo?.DBB?.content.replace(' ', '') || "",
                "id": 4,
                "location": "",
                "name": "dob",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "Issue Date",
                "confidence": 84,
                "field_value_original": licenseInfo?.DBD?.content.replace(' ', '') || "",
                "id": 5,
                "location": "",
                "name": "issue_date",
                "section_name": "",
                "validation_required": "No"
            },
            {
                "alias": "Expiration Date",
                "confidence": 84,
                "field_value_original": licenseInfo?.DBA?.content.replace(' ', '') || "",
                "id": 6,
                "location": "",
                "name": "expiration_date",
                "section_name": "",
                "validation_required": "NO"
            },
            {
                "alias": "Doc/State Type",
                "confidence": 92,
                "field_value_original": licenseInfo?.DAJ?.content.replace(' ', '') || '',
                "id": 156,
                "location": "",
                "name": "doc_state_type",
                "section_name": "",
                "validation_required": "No"
            }
        ];
        Save({
            "file": imageSrc,
            "document_id": 13,
            "trn_id": id,
            data: {
                OCRData: MYOCRData,
                raw_data: localStorage.getItem('raw'),
            }
        })
    }, [webcamRef]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                navigate(-1);
                            }}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Capture Back Side
                        </Typography>
                    </Toolbar>
                </AppBar>
                {Result.isLoading && <LinearProgress />}
            </Box>
            <Container maxWidth="sm">
                <Box sx={{ bgcolor: '#cfe8fc', height: '91vh' }} >
                    <Stack direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}>
                        <div><br /></div>
                        <div style={{ padding: "10px" }}>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={"100%"}
                                videoConstraints={!cameraFace ? videoConstraints : videoEnvironment}
                            />
                            <Typography variant="p" component="div" sx={{ flexGrow: 1, padding: "10px" }}>
                                Please capture the back of your ID                            </Typography>
                        </div>
                    </Stack>
                </Box>
            </Container>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, backgroundColor: 'rgba(245, 245, 245, 0.9)' }}>
                <Toolbar>
                    <IconButton onClick={() => { setCameraFace(!cameraFace) }} sx={{ backgroundColor: '#fff' }} aria-label="open drawer">
                        <CameraswitchIcon />
                    </IconButton>
                    <StyledFab disabled={Result.isLoading} onClick={() => { capture() }} sx={{ backgroundColor: "rgba(245, 245, 245, 0.4)" }} aria-label="add">
                        <Camera />
                    </StyledFab>
                    <Box sx={{ flexGrow: 1 }} />
                </Toolbar>
            </AppBar>
        </>
    );
};

export default CameraScanBack;