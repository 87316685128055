import CurrencyInput from "react-currency-input-field";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
const InputCurrencyField = (props) => {
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { control } = useFormContext();
  const { name } = props.register;

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const { onChange, ...rest } = field;
          const error = fieldState.error !== undefined;
          return (
            <>
              <label
                htmlFor={name}
                className={`form-label ${error && "text-danger"}`}
              >
                {props.label} {required && <span className="red">*</span>}
              </label>
              <CurrencyInput
                allowNegativeValue={false}
                precision={2}
                id={name}
                onValueChange={(value, name) => {
                  console.log(
                    "On Change value for input and currency field",
                    value
                  );
                  onChange(value);
                }}
                className={`form-control cus-input-control ${
                  required && !field.value && "is-warning"
                } ${error && "is-invalid"}`}
                placeholder={props.placeholder || `Enter ${props.label}`}
                decimalsLimit={2}
                prefix="$ "
                {...rest}
              />
              <div className="invalid-feedback">
                {error && fieldState.error.message}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default InputCurrencyField;
