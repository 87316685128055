/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import PaymentSignature from "./payment-signature/payment-signature";
import TermsCondition from "./terms-condition/terms-condition";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import InputCheckBoxField from "../../registration/Inputs/InputCheckBoxField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  AccordionDetails,
  Snackbar,
} from "@mui/material";
import { tabsStateAction } from "../../../store/identification/tabStateSlice";
import MuiAlert from "@mui/material/Alert";

import CloseIcon from "@mui/icons-material/Close";
import { DevTool } from "@hookform/devtools";
import {
  useGetSectionStatusQuery,
  useGetSubscriberFeesQuery,
  useGetTermandCondtionQuery,
} from "../../../services/identificationApi";
import { sideNavAction } from "../../../store/identification/sideMenuSlice";
import SupportSection from "../../common/SupportSection/SupportSection";
import parse from 'html-react-parser';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ConsentPayment = (props) => {
  const [callSubmit, setCallSubmit] = useState(false);
  const [subscriberFee, setSubscriberFee] = useState(0);
  const [applicantFee, setApplicantFee] = useState(0);
  const [clickHere, setClickHere] = React.useState(true);
  const formSectionSubmit = useSelector(
    (state) => state.formSubmit.fromSectionSubmit
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const submitBtnRef = useRef(null);
  const dispatch = useDispatch();
  React.useEffect(() => {
    document.title = "Consent & Payment";
  }, []);
  useEffect(() => {
    (async () => {
      if (formSectionSubmit) {
        if (!(await handleSubmitValidation("terms"))) return;
        if (!(await handleSubmitValidation("payment"))) return;
        submitBtnRef?.current?.click();
      }
    })();
  }, [formSectionSubmit]);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );

  const { data } = useGetTermandCondtionQuery(trn_id)
  // .getDetails('${trn_id}').data
  const nj_status = useSelector((state) => state.identificationApi.queries);
  const sectionStatus = useGetSectionStatusQuery(trn_id);

  const termsStatus = useSelector(
    (state) => state.identification.tabsStateSlice.termsStatus
  );

  const paymentStatus = useSelector(
    (state) => state.identification.tabsStateSlice.paymentStatus
  );

  const paymentErr = useSelector(
    (state) => state.identification.tabsStateSlice.paymentErr
  );

  const getSubscriberFeesData = useGetSubscriberFeesQuery(trn_id);

  useEffect(() => {
    const fees_data = getSubscriberFeesData?.data;
    if (fees_data) {
      setSubscriberFee(fees_data?.records?.recordset[0]?.SubscriberFee);
      setApplicantFee(fees_data?.records?.recordset[0]?.ApplicationFee);
      dispatch(
        sideNavAction.enterApplicationFee(
          fees_data?.records?.recordset[0]?.ApplicationFee * 100
        )
      );
    }
  }, [getSubscriberFeesData?.data]);

  useEffect(() => {
    if (sectionStatus?.data?.consent_payment_status === true) {
      dispatch(tabsStateAction.Payment(true));
    }
  }, [dispatch, sectionStatus?.data]);

  const schema = yup.object().shape({
    billingAddress: yup.string().required("required").matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Only Alphabet and Numbers are Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
    state: yup.object().typeError("required").required("required"),
    city: yup.string().typeError("required").required("required").matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Only Alphabet and Numbers are Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
    zipCode: yup
      .string()
      .typeError("required")
      // .matches(/^(\d{5})$/g, "Please enter valid zip code")
      .matches(
        /^(\d{4}[0-9]|\d{8}[0-9]|\d{4}[0-9]-\d{3}[0-9]|-----\d{3}[0-9])$/g,
        "Please enter valid zip code"
      )
      .required("required"),
    cname: yup.string().typeError("required").required("required").matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Only Alphabet and Numbers are Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
  });

  const methods = useForm({
    defaultValues: {
      city: "",
      state: "",
      zipCode: "",
      concent: false,
      copyapplicant: false,
      copybillingaddress: false,
      copyapplicantname: false,
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (fromData) => {
    console.log("fromData", fromData);
    setCallSubmit(true);
    setCallSubmit(false);
    // navigate("/success");
  };

  const handleSubmitValidation = async (parms) => {
    if (parms === "terms") {
      const localSchema = yup.object().shape({
        concent: yup.bool().oneOf([true], "This field must be checked"),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Terms(false));
          dispatch(tabsStateAction.PaymentErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    } else if (parms === "payment") {
      const localSchema = yup.object().shape({
        billingAddress: yup.string().required("required"),
        state: yup.object().typeError("required").required("required"),
        zipCode: yup.string().typeError("required").required("required"),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (!result) {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Payment(false));
          dispatch(tabsStateAction.PaymentErr(true));
          return false;
        }
        return true;
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    }
  };

  const handleChange = async (parms) => {
    if (parms === "terms") {
      const localSchema = yup.object().shape({
        concent: yup.bool().oneOf([true], "This field must be checked"),
      });
      try {
        const result = await localSchema.isValid(methods.getValues());
        if (result) {
          methods.clearErrors();
          dispatch(tabsStateAction.Terms(true));
          dispatch(tabsStateAction.PaymentErr(false));
        } else {
          submitBtnRef?.current?.click();
          dispatch(tabsStateAction.Terms(false));
          dispatch(tabsStateAction.PaymentErr(true));
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: applicant-details.js ~ line 687 ~ handleChange ~ error",
          error
        );
      }
    }
  };
  const navigate = useNavigate();
  const { hash } = useLocation();
  useEffect(() => {
    if (!hash) return;
    if (hash == "#tc") {
      navigate(-1);
      return dispatch(tabsStateAction.Terms(false));
    }
    if (hash == "#ps") {
      navigate(-1);
      return handleChange("terms");
    }
  }, [hash]);

  return (
    <div id="con-pay-tabs">
      {/* {JSON.stringify(data.records.text)} */}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Accordion
            expanded={!termsStatus}
            onChange={() => {
              dispatch(tabsStateAction.Terms(false));
            }}
          >
            <AccordionSummary
              expandIcon={termsStatus && <AiOutlinePlus />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                {termsStatus && <BsCheck2Circle color="green" />} Terms and
                Conditions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="static-info">
                {data?.records?.text && (<>
                  {parse(data?.records?.text)}
                </>)}

                {data?.records?.subscriber_text && (<>
                  <br/>
                  {parse(data?.records?.subscriber_text)}
                </>)}
                {/* <div>
                  <p className="para-format">
                    Docuverus charges a <strong>NON-REFUNDABLE</strong>{" "}
                    APPLICATION FEE of {`$` + applicantFee}.00 per applicant{" "}
                    (the "Application Fee"), which covers certain processing and
                    screening service costs associated with your application
                    submitted herein (the "Application"), such as verifying your
                    income, checking your credit, and conducting a background
                    check. Under no circumstances shall Applicant be entitled to
                    a refund or charge-back of the Application Fee. The
                    Application Fee, which must be paid at the time that the
                    Applicant submits a rental application, is separate and
                    apart from any real estate brokerage that Applicant may be
                    responsible for paying to a Broker or my have otherwise
                    agreed to pay to a Broker.
                  </p>
                  <p className="para-format">
                    Applicant represents and warrants that all of the
                    information contained in the Application is true and
                    accurate. Applicant expressly understands and agrees that
                    submitting this Application does not guarantee that it will
                    be accepted by the applicable landlord or property
                    management company. Applicant acknowledges and agrees that
                    Application acceptance is solely within the discretion of
                    landlord and/or property management company and neither
                    Docuverus, nor National Tenant Network (defined below), is
                    responsible for determining whether Applicant's application
                    is accepted or denied. Applicant further acknowledges and
                    agrees that landlord(s) and/or property management company
                    may deny Applicant's application or terminate any lease
                    agreement with Applicant if any information contained herein
                    is false, incomplete, unverifiable, and/or misleading.
                    Applicant shall not be entitled to a refund or charge-back
                    of the Application Fee in the event that the application is
                    rejected or denied for any reason.
                  </p>
                  <p className="para-format">
                    As part of the application process, National Tenant Network
                    will conduct a background screening report, In order for
                    National Tenant Network and any prospective landlord to
                    process your Application, Applicant must provide written
                    consent to conduct or run certain searches, inquiries, or
                    reports, which may include accessing your credit report
                    (from Equifax, Experian or TransUnion), eviction report,
                    criminal background report, sex offender report,
                    income/employment verification, residency verification and
                    OFAC terrorist search. By completing this Application and
                    signing below, you consent to National Tenant Network
                    conducting such searches in accordance with the Fair Credit
                    Reporting Act. You are also consenting to Docuverus sharing
                    your Application with National Tenant Network for the
                    purpose of running a background screening report.
                  </p>
                  <p className="para-format">
                    Applicant consents to Docuverus sharing all application
                    information and documents with Anytown Real Estate Ltd, and
                    Applicant also consents to Anytown Real Estate Ltd providing
                    a copy of Applicant's application and/or background report
                    to a prospective Landlord and/or his/her respective listing
                    agent, if applicable, in connection with the rental property
                    applied for.
                  </p>
                  <p className="para-format">
                    Federal and State Disclosures <br />
                    <a
                      href="https://files.consumerfinance.gov/f/documents/bcfp_consumer-rights-summary_2018-09.pdf"
                      target={"_blank"}
                    >
                      Click here
                    </a>{" "}
                    for a Summary of Your Rights Under the Fair Credit Reporting
                    Act.
                  </p>
                  <p className="para-format">
                    <p className="consent-payment-country-tags">
                      Washington State applicants only:
                    </p>{" "}
                    You also have the right to request from the consumer
                    reporting agency a written summary of your rights and
                    remedies under the Washington Fair Credit Reporting Act.
                  </p>
                  <p className="para-format">
                    <p className="consent-payment-country-tags">
                      New York/Maine applicants only:
                    </p>
                    You have the right to inspect and receive a copy of any
                    investigative consumer report requested by the Landlord by
                    contacting the consumer reporting agency identified
                    directly. You may also contact the Company to request the
                    name, address and telephone number of the nearest unit of
                    the consumer reporting agency designated to handle
                    inquiries, which the Company shall provide within 5 days.
                  </p>
                  <p className="para-format">
                    <p className="consent-payment-country-tags">
                      New York City applicants only:
                    </p>{" "}
                    Pursuant to federal, state, and local law: 1) If we take
                    adverse action against you on the basis of information
                    contained in a tenant screening report, we must notify you
                    that such action was taken and supply you with the name and
                    address of the consumer reporting agency that provided the
                    tenant screening report on the basis of which such action
                    was taken; 2) if any adverse action is taken against you
                    based on information contained in a tenant screening report,
                    you have the right to inspect and receive a free copy of
                    that report by contacting the consumer reporting agency:
                    NTN-New York, PO Box 1023, Turnersville, NJ 08012,
                    Telephone: 800-422-8299; 3) every tenant or prospective
                    tenant is entitled to one free tenant screening report from
                    each national consumer reporting agency annually, in
                    addition to a credit report that should be obtained from
                    www.annualcreditreport.com; and 4) every tenant or
                    prospective tenant may dispute inaccurate or incorrect
                    information contained in a tenant screening report directly
                    with the consumer reporting agency.
                  </p>
                  {nj_status[`getDetails("${trn_id}")`]["data"]
                    ?.convicted_for_crime?.display === false && (
                    <p className="para-format">
                      <p className="consent-payment-country-tags">
                        New Jersey Applicants:
                      </p>{" "}
                      The Fair Chance in Housing Act requires specific notice to
                      be provided to you, which can be found{" "}
                      <a
                        href="https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:926778a9-9ad7-40e6-9c94-de6e801279ea"
                        target={"_blank"}
                      >
                        here.
                      </a>{" "}
                      By signing this application, you agree that you have
                      received and acknowledge this notice.
                    </p>
                  )}
                  <p className="para-format">
                    <p className="consent-payment-country-tags">
                      California applicants or residents only:
                    </p>
                    <p>
                      By signing this application, you also acknowledge receipt
                      of the NOTICE REGARDING BACKGROUND INVESTIGATION PURSUANT
                      TO CALIFORNIA LAW. Please contact the consumer reporting
                      agency if you would like to receive a copy of an
                      investigative consumer report or consumer credit report at
                      no charge if one is obtained by the Company whenever you
                      have a right to receive such a copy under California.
                    </p>
                  </p>
                  
                </div> */}
                <div className="tc-check mr-t60">
                  <div className="row">
                    <div className="col-sm-8 col-md-8 col-lg-8 mx-auto">
                      <div className="cus-check red-check mr-b15 mr-t10">
                        <input
                          type="checkbox"
                          name="concent"
                          {...methods.register(`concent`)}
                          id="concent"
                        />
                        <label
                          htmlFor="concent"
                          className="mr-b0 d-flex align-items-center"
                        >
                          <span className="mr-r10"></span>{" "}
                          <p>
                            I have read, understood, and consent to
                            Docuverus's{" "}
                            <a
                              href="http://40.71.253.227/DataPolicy/Index"
                              target="_blank"
                              rel="noreferrer"
                              className={`${!methods.watch(`concent`)
                                ? "red"
                                : "app-blue-color"
                                }`}
                            >
                              Data Policy{" "}
                            </a>
                            and{" "}
                            <a
                              href="http://40.71.253.227/TermsOfUse/Index"
                              target="_blank"
                              rel="noreferrer"
                              className={`${!methods.watch(`concent`)
                                ? "red"
                                : "app-blue-color"
                                }`}
                            >
                              Terms of Use.
                            </a>{" "}
                            I also authorize Docuverus, LLC to charge my
                            credit or debit card the NON-REFUNDABLE
                            Application Fee of {"$" + applicantFee}.00
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            disabled={!methods.watch("concent")}
            expanded={!paymentStatus && methods.watch("concent")}
            onChange={() => {
              handleChange("terms");
            }}
          >
            <AccordionSummary
              expandIcon={paymentStatus && <AiOutlinePlus />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>
                {paymentStatus && <BsCheck2Circle color="green" />} Payment &
                Signature
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentSignature
                data={props?.data}
                callSubmit={callSubmit}
                applicationFee={applicantFee}
              />
            </AccordionDetails>
          </Accordion>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={paymentErr}
            key={1}
          >
            <Alert
              onClick={() => {
                dispatch(tabsStateAction.PaymentErr(false));
              }}
              severity="error"
            >
              {" "}
              Answer the required field <CloseIcon />
            </Alert>
          </Snackbar>

          <button
            ref={submitBtnRef}
            style={{ display: "none" }}
            type="submit"
            className="btn btn-info"
          >
            Next
          </button>
        </form>
      </FormProvider>
      <DevTool control={methods.control} />
      <div className="mt-4">
        <SupportSection clickHere={clickHere} setClickHere={setClickHere} />
      </div>
    </div>
  );
};

export default ConsentPayment;
