import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetIncomeBankingQuery,
  useGetSectionStatusQuery,
} from "../../../services/identificationApi";
import IncomeBankingOtherForm from "./income-banking-other-form";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import Card from "@mui/material/Card";
import { sideNavAction } from "../../../store/identification/sideMenuSlice";

const IncomeBanking = (props) => {
  const dispatch = useDispatch();
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  let sectionCompleteStatus = useGetSectionStatusQuery(trn_id);
  console.log(
    "Check Section Status Complete in Identification",
    sectionCompleteStatus
  );
  dispatch(sideNavAction.applicationStatusState(sectionCompleteStatus.data));
  const { isLoading, isSuccess, isError, data } =
    useGetIncomeBankingQuery(trn_id);
  console.log("IB:25", data);
  React.useEffect(() => {
    document.title = "Income & Banking";
  }, []);

  if (isError) return <h1>API FAIL</h1>;

  if (isLoading || !isSuccess)
    return (
      <Box sx={{ width: "100%" }}>
        <Card variant="outlined" style={{ padding: "10px" }}>
          <LinearProgress />
        </Card>
      </Box>
    );
  return <IncomeBankingOtherForm />;
};

export default IncomeBanking;
