import React, { useEffect, useState, useRef } from "react";
//import $ from "jquery";

import {
  useGetIdentificationDataQuery,
  useGetDetailsQuery,
} from "../../services/identificationApi";
import Loader from "react-loader-spinner";
import { useGetMasterRegistrationDataQuery } from "../../services/registrationApi";
import SuperHeader from "../../components/common/superHeader/superHeader";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  formSubmitStatus,
  formSubmitStatusRest,
} from "../../store/registration/fromSubmitSlice";
import { FaCheck } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { protocol } from "../../config";

const IdentificationView = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const sideMenuSlice = useSelector((state) => {
  //   return state.identification.sideMenuSlice;
  // });
  //const { selected } = sideMenuSlice;
  const location = useLocation();
  // const dispatch = useDispatch();
  // const [selectedTap, setSelectedTap] = useState("income");
  //const [logo, setLogo] = useState("");
  // const [selectedNext, setSelectedNext] = useState("");
  // const [getCompanyLogo, result] = useGetCompanyLogoQuery();
  // const applicantRef = useRef();

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const { data, isLoading, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });

  let identificationData = useGetIdentificationDataQuery(trn_id);

  let contextualData = useGetDetailsQuery(trn_id);

  if (isLoading && identificationData.isLoading && contextualData.isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  else
    return (
      <main className="body-wrap">
        <SuperHeader />

        <section className="top-white-row">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-sm-6">
                <div className="w-r-logo">
                  <img
                    src={`${data?.records?.subrciber_logo}`}
                    className="w-r-logo-img"
                    alt=""
                    title=""
                  />
                  <span className="res-company-name">
                    {data?.records?.subscriber_name}
                  </span>
                </div>
              </div>
              <div className="col col-sm-6">
                <div
                  className="t-w-appid text-right d-flex"
                  style={{ justifyContent: "end" }}
                >
                  <h5 className="mr-r10">
                    Name:{" "}
                    <span>
                      {" "}
                      {data?.records?.first_name} {data?.records?.last_name}
                    </span>
                  </h5>
                  <h5>
                    Application ID:{" "}
                    <span>{data?.records?.application_number}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-sm-12 col-md-12 col-lg-12 ms-4 mt-2 "
              style={{ boxSizing: "border-box" }}
            >
              <IoMdArrowRoundBack
                className="cursor-pointer"
                onClick={() => navigate(-1)}
                size={25}
              />
            </div>
          </div>
        </div>
        <section className="application-body-wrap">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="id-steper-wrap">
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-3">
                      <div className="tabs-nav-block white-box xs-tabs-btn">
                        <div className="s-tab-list">
                          <ul className="nav nav-tabs flex-column">
                            <li
                              className={`nav-item ${
                                location.pathname === "/view" && "site-active"
                              } ${
                                location.pathname === "/view/" && "site-active"
                              } `}
                            >
                              <a
                                className={`nav-link `}
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(formSubmitStatusRest());
                                  navigate("/view");
                                }}
                              >
                                <span className="s-no-complete s-no-com">
                                  <FaCheck />
                                </span>
                                <span className="complete-step">
                                  Identification Details
                                </span>
                              </a>
                              <div className="nav-sub-tabs">
                                <ul>
                                  <li className="active-sub">
                                    <a href="#personal-details">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Personal Details
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#poi">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Proof of Identity
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#address">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Address
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#ssn">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      SSN
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              className={`nav-item ${
                                location.pathname === "/view/applicantDetail" &&
                                "site-active"
                              } ${
                                location.pathname ===
                                  "/view/applicantDetail/" && "site-active"
                              }`}
                            >
                              <a
                                className={`nav-link`}
                                id="agent-tab"
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(formSubmitStatusRest());
                                  navigate("applicantDetail");
                                }}
                              >
                                <span className="s-no-complete s-no-com">
                                  <FaCheck />
                                </span>
                                <span className="complete-step">
                                  Applicant Details
                                </span>
                              </a>
                              <div className="nav-sub-tabs">
                                <ul>
                                  <li className="active-sub">
                                    <a href="#ad">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Applicant Details
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#contacts">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Contacts
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#fd">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Financial Details
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#others">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Others
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              className={`nav-item ${
                                location.pathname === "/view/incomeDetail" &&
                                "site-active"
                              } ${
                                location.pathname === "/view/incomeDetail/" &&
                                "site-active"
                              } `}
                            >
                              <a
                                className={`nav-link`}
                                id="desired-tab"
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(formSubmitStatusRest());
                                  navigate("incomeDetail");
                                }}
                              >
                                <span className="s-no-complete s-no-com">
                                  <FaCheck />
                                </span>
                                <span className="complete-step">
                                  Income & Banking
                                </span>
                              </a>
                              <div className="nav-sub-tabs">
                                <ul>
                                  <li className="active-sub">
                                    <a href="#ei">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Employment Income
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#other-in">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Other Income
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#bank-info">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Banking Information
                                    </a>
                                  </li>
                                  <li className="active-sub">
                                    <a href="#od">
                                      <svg
                                        width="16"
                                        height="8"
                                        viewBox="0 0 16 8"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.3527 4.66703C15.5479 4.47177 15.5479 4.15519 15.3527 3.95992L12.1707 0.777944C11.9754 0.582682 11.6588 0.582681 11.4636 0.777944C11.2683 0.973206 11.2683 1.28979 11.4636 1.48505L14.292 4.31348L11.4636 7.1419C11.2683 7.33717 11.2683 7.65375 11.4636 7.84901C11.6588 8.04427 11.9754 8.04427 12.1707 7.84901L15.3527 4.66703ZM0.0419921 4.81348L14.9991 4.81348L14.9991 3.81348L0.0419922 3.81348L0.0419921 4.81348Z"
                                          fill="#848484"
                                        />
                                      </svg>
                                      Other Documets
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-9 xs-tabs-bg">
                      <div className="tabs-wrap">
                        <Outlet />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default IdentificationView;
