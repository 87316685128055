import React, { useState } from "react";
import { Controller, useController, useFormContext, useFormState } from "react-hook-form";
import "./index.scss";

const InputPasswordField = (props) => {
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { control } = useFormContext();
  const { name } = props.register;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const [style, setStyle] = useState({ color: "red", percentage: "0%" });

  return (
    <>
      <label htmlFor={name} className={`form-label ${error && "text-danger"}`}>
        {props.label} {required && <span className="red">*</span>}
      </label>
      <div className="pass-progress">
        <Controller
          control={props.control}
          name={name}
          render={({ field }) => {
            const { onChange, ...rest } = field;
            return (
              <>
                <input
                  type="password"
                  placeholder={props.placeholder || `Enter ${props.label}`}
                  className={`form-control cus-input-control ${
                    required && !field.value && "is-warning"
                  } ${error && "is-invalid"}`}
                  {...rest}
                  onBlur={(e) => {
                    
                    const dependentFun = props.triggerDependent || null;
                    if (dependentFun) {
                      if (!e.target.value == "") dependentFun(e);
                    }
                    rest.onBlur(e);
                  }}
                  onChange={(e) => {
                    const strongRegex = new RegExp(
                      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                    );
                    const mediumRegex = new RegExp(
                      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
                    );
                    if (strongRegex.test(e.target.value)) {
                      setStyle({ color: "green", percentage: "100%" });
                    } else if (mediumRegex.test(e.target.value)) {
                      setStyle({ color: "yellow", percentage: "70%" });
                    } else {
                      const percentage = (e.target.value.length / 8) * 40;
                      setStyle({ color: "red", percentage: percentage + "%" });
                    }
                    onChange(e);
                  }}
                  id={name}
                />
                <div className="invalid-feedback">{error && error.message}</div>
                <small className="form-text text-muted">{props.hint}</small>
                <div className="progress mr-t10">
                  <div
                    className="progress-bar"
                    style={{
                      width: style.percentage,
                      background: style.color,
                    }}
                  ></div>
                </div>
                {error && (
                  <div className="pass-msg-box">
                    <h5>
                      <span>Weak</span> - {error.message}
                    </h5>
                  </div>
                )}
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default InputPasswordField;
