/**
 * LOCAL
 */
// export const protocol = "http://";
// export const BASE_URL_API = "http://localhost:5090";
// export const BASE_URL_LOCAL = "http://localhost:3000/"; //process.env.REACT_APP_BASE_URL;

/**
 * UAT
 */
// export const protocol = "https://";
// export const BASE_URL_API = "https://uatapiml.dvapply.com/";
// export const BASE_URL_LOCAL = "https://uatrevamp.dvapply.com/"; //process.env.REACT_APP_BASE_URL;
// export const sk = "sk_test_ebAyKD0JSQGM4GdbEfju1ij000UTMr1MSL";
// export const pk = "pk_test_9ZkpCpGvA66YFwajWDCFQNoX";
// export const logout_url = "https://uatats.dvapply.com";

/**
 * LIVE
 */
export const protocol = "https://";
export const BASE_URL_LOCAL = "https://prodapplicant-app.dvapply.com/"; //process.env.REACT_APP_BASE_URL;
export const BASE_URL_API = "https://ats-api.dvapply.com"; //process.env.REACT_APP_BASE_URL;
export const sk = "sk_live_BubD7nMJ7OxoeB0JAImHS7Oz00VzfBmkce";
export const pk = "pk_live_IaVLDOel1ZP3b76vZKamCSEC";
export const logout_url = "https://ats-app.dvapply.com";
