import React, { useCallback } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import {
  useDeleteDocumentMutation,
  useGetSecondaryAppDetailsQuery,
  useSaveSecondaryAppMutation,
  useUploadFileServiceMutation,
} from "../../services/identificationApi";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputTextArea from "../../components/registration/Inputs/InputTextArea";
import CustomRadioButton from "../../components/registration/Inputs/customRadioButton";
import { RiInformationLine } from "react-icons/ri";
import { UploadFile } from "@mui/icons-material";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
import { Box } from "@mui/system";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { Button } from "bootstrap";
import { protocol } from "../../config";

function SecondaryApp(props) {
  const [uploadFileRequest, result] = useUploadFileServiceMutation();
  const [saveSecondaryApp, apiResult] = useSaveSecondaryAppMutation();
  const [open, setOpen] = React.useState(false);
  const [uploadFlag, setUploadFlag] = React.useState(false);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { data, isLoading, isError } = useGetSecondaryAppDetailsQuery(trn_id);
  console.log("SecondaryApp:11", data);
  const [deleteDocument, deleteDocResult] = useDeleteDocumentMutation();
  const deleteDocumentHandler = (id) => {
    deleteDocument(id).then((response) => {
      console.log("delete Document resopnse", response);
      setOpen(false);
      if (response.data.success === true) {
        //
      }
    });
  };

  const schema = yup.object().shape({
    convicted_for_crime: yup.object().shape({
      response: yup
        .string()
        .typeError("Response Required")
        .required("Response Required"),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value === "Yes";
          },
          then: yup.string().typeError("required").required("required"),
        }),
      required: yup.string(),
      display: yup.string(),
      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
    // convictions_on_property: yup.object().shape({
    //   response: yup
    //     .string()
    //     .typeError("Response Required")
    //     .required("Response Required"),
    //   explanation: yup
    //     .string()
    //     .nullable()
    //     .when("response", {
    //       is: (value) => {
    //         return value === "Yes";
    //       },
    //       then: yup.string().typeError("required").required("required"),
    //     }),
    //   required: yup.string(),
    //   display: yup.string(),
    //   ProfileTabFieldID: yup.string().when("required", {
    //     is: true,
    //     then: yup.string().required("required"),
    //   }),
    //   Question: yup.string(),
    // }),
    convictions_on_property: yup.object().shape({
      response: yup.string().when("convicted_for_crime.response", {
        is: (value) => {
          return methods.watch("convicted_for_crime.response") == "Yes";
        },
        then: yup.string().typeError("required").required("required"),
      }),
      explanation: yup
        .string()
        .nullable()
        .when("response", {
          is: (value) => {
            return value === "Yes";
          },
          then: yup.string().typeError("required").required("required"),
        }),
      required: yup.string(),
      display: yup.string(),
      ProfileTabFieldID: yup.string().when("required", {
        is: true,
        then: yup.string().required("required"),
      }),
      Question: yup.string(),
    }),
  });

  const methods = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
    mode: "all",
  });

  React.useEffect(() => {
    const subscribe = methods.watch((value, { name, type }) => {
      console.log("VAL:120", value, name, type);
      if (type === "change" && name === "convicted_for_crime.response") {
        if (methods.watch("convicted_for_crime.response") == "No") {
          methods.setValue("convicted_for_crime.explanation", "");
        }
      }
      if (type === "change" && name === "convictions_on_property.response") {
        if (methods.watch("convictions_on_property.response") == "No") {
          methods.setValue("convictions_on_property.explanation", "");
        }
      }
      if (type === "change" && name === "proof_of_document.response") {
        if (methods.watch("proof_of_document.response") == "No") {
        //  setOpen(true);
        } else {
         // setOpen(false);
        }
      }
    });
    return () => subscribe.unsubscribe();
  }, [methods]);

  React.useEffect(() => {
    const data_form_value = methods.getValues();
    const api_data = data;
    const mapData = {
      ...api_data,
      convicted_for_crime: {
        ...api_data?.convicted_for_crime,
        response: data_form_value?.convicted_for_crime?.response
          ? data_form_value?.convicted_for_crime?.response
          : api_data?.convicted_for_crime?.response,
        explanation: data_form_value?.convicted_for_crime?.explanation
          ? data_form_value?.convicted_for_crime?.explanation
          : api_data?.convicted_for_crime?.explanation,
      },
      convictions_on_property: {
        ...api_data?.convictions_on_property,
        response: data_form_value?.convictions_on_property?.response
          ? data_form_value?.convictions_on_property?.response
          : api_data?.convictions_on_property?.response,
        explanation: data_form_value?.convictions_on_property?.explanation
          ? data_form_value?.convictions_on_property?.explanation
          : api_data?.convictions_on_property?.explanation,
      },
      proof_of_document: {
        ...api_data?.proof_of_document,
        files: api_data?.proof_of_document?.files,
        response: data_form_value?.proof_of_document?.response
          ? data_form_value?.proof_of_document?.response
          : api_data?.proof_of_document?.response,
      },
    };
    methods.reset(mapData);
    console.log("DFV", data_form_value);
    console.log("AD", api_data);
  }, [data, methods]);

  if (isLoading) {
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  }
  if (isError) {
    return (
      <>
        <h1>Error in getting questions.</h1>
      </>
    );
  }
  const uploadFile = (file) => {
    var formData = new FormData();
    formData.append("files", file);
    formData.append("trn_id", trn_id);
    formData.append("document_id", 15);
    formData.append("source_id", 1); //7929
    console.log(formData);

    uploadFileRequest(formData).then((response) => {
      console.log("Upload File Response Data", response);
      if (response.data.success === true) {
        // dispatch(sideNavAction.uploadPetImageId(response?.data?.records?.id));
      }
      // if (props.verifyDetails) {
      //   props.verifyDetails();
      // }
    });
  };

  const onSubmit = async (data) => {
    console.log("SECONDARY:98", data);
    debugger;
    let questions = [];
    questions[0] = data?.convicted_for_crime;
    questions[1] = data?.convictions_on_property;
    // questions[2] = data?.proof_of_document;
    let payload = {
      trn_id,
      questions: questions,
    };
    const saveSecondaryAppResponse = await saveSecondaryApp(payload);
    console.log("APIRES:37", saveSecondaryAppResponse);
    if (saveSecondaryAppResponse?.data?.success) {
      toast.success("Successfully save 👌");
      setTimeout(() => {
        props?.setOneStepDialog(false);
      }, 2000);
    } else {
      toast.error("Saving failed 🤯");
    }

    const response = await toast.promise(saveSecondaryApp(payload), {
      pending: "Saving In Progress",
      success: "Successfully save 👌",
      error: "Saving failed 🤯",
    });
    if (response) {
      //
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
        <fieldset disabled={props?.fieldset ? props?.fieldset : false}>
          <div className="container">
            <div className="row">
              <div style={{ height: "10px" }} />
              <div className="col-sm-12 col-md-12 col-lg-12">
                <>
                  <div className="col-sm-12">
                    <div className="i-a-block mr-b15">
                      <CustomRadioButton
                        label="1. Have you ever been convicted of a crime?"
                        register={methods.register(
                          "convicted_for_crime.response"
                        )}
                      />
                    </div>
                  </div>
                  {methods.watch("convicted_for_crime.response") === "Yes" && (
                    <div className="col-sm-12">
                      <span
                        className="mb-2"
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        Please describe the nature of your criminal history,
                        including the offense(s) committed, when the offense(s)
                        were committed, if applicable, any mitigating
                        information related to the offense(s) committed, and if
                        applicable, any evidence of rehabilitative actions taken
                        following your conviction(s).
                      </span>
                      <div style={{ height: "1.5rem" }} />
                      <div className="blue-box pd15 mr-b30">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <InputTextArea
                            notRequired={methods.getValues(
                              "convicted_for_crime.required"
                            )}
                            label=""
                            register={methods.register(
                              `convicted_for_crime.explanation`
                            )}
                            placeholder="Please Explain"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              </div>
              {
                <>
                  {methods.watch("convicted_for_crime.response") === "Yes" && (
                    <>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <>
                          <div className="col-sm-12">
                            <div className="i-a-block mr-b15">
                              <CustomRadioButton
                                label="2. Did any of your convictions occur on a property that you were renting?"
                                register={methods.register(
                                  "convictions_on_property.response"
                                )}
                              />
                            </div>
                          </div>
                          {methods.watch("convictions_on_property.response") ===
                            "Yes" && (
                            <div className="col-sm-12">
                              <span
                                className="mb-2"
                                style={{ fontSize: "14px", fontWeight: "600" }}
                              >
                                Please describe the circumstances surrounding
                                the offense(s) committed on rented property.
                              </span>
                              <div style={{ height: "1.5rem" }} />
                              <div className="blue-box pd15 mr-b30">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                  <InputTextArea
                                    notRequired={methods.getValues(
                                      "convictions_on_property.required"
                                    )}
                                    label=""
                                    register={methods.register(
                                      `convictions_on_property.explanation`
                                    )}
                                    placeholder="Please Explain"
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="col-sm-12">
                            <div className="i-a-block mr-b15">
                              <div>
                                <CustomRadioButton
                                  onClickSecondary={() => {
                                    setOpen(true);
                                  }}
                                  type="secondaryApp"
                                  label="3. Do you have any related proof documentation or character refrences you wish to upload?"
                                  register={methods.register(
                                    "proof_of_document.response"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <>
                              {methods.watch("proof_of_document.response") ==
                                "Yes" && (
                                <div className="blue-box">
                                  <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="d-flex justify-content-between align-items-center mr-t20">
                                      <div className="d-flex flex-column ssn-text-block">
                                        <h4>
                                          Proof Documentation / Character
                                          Reference{" "}
                                          <span className="red">*</span>
                                          <button
                                            type="button"
                                            className="info-btn"
                                          >
                                            <img
                                              src="images/info-icon.png"
                                              alt=""
                                            />
                                          </button>
                                        </h4>
                                        <small>
                                          Click the (i) button above for details
                                        </small>
                                        <div className="d-flex justify-content-start">
                                          {methods.watch(
                                            "proof_of_document.files"
                                          )?.length > 0 && (
                                            <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                                              <BsFillFileEarmarkPdfFill
                                                className="cursor-pointer"
                                                color="#F40F02"
                                                size={40}
                                                onClick={() =>
                                                  window
                                                    .open(
                                                      `${data?.proof_of_document?.files[0]}`,
                                                      "_blank"
                                                    )
                                                    .focus()
                                                }
                                              />
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  setOpen(true);
                                                }}
                                                title={`Delete`}
                                                className="btn btn-link btn-sm text-danger"
                                              >
                                                <BiTrash /> Delete
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <>
                                        <Dropzone
                                          multiple={true}
                                          onDrop={(file) => {
                                            for (let f of file) {
                                              console.log(
                                                "Check File:223",
                                                file
                                              );
                                              uploadFile(f);
                                            }

                                            // console.log("🚀 ~ file: CustomUpload.js ~ line 55 ~ CustomUpload ~ file", file);
                                          }}
                                          accept="image/*,application/pdf"
                                          minSize={1024}
                                          maxSize={3072000}
                                        >
                                          {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                            isDragAccept,
                                            isDragReject,
                                          }) => {
                                            const additionalClass = isDragAccept
                                              ? "accept"
                                              : isDragReject
                                              ? "reject"
                                              : "";

                                            return (
                                              <div
                                                {...getRootProps({
                                                  className: `dropzone ${additionalClass}`,
                                                })}
                                              >
                                                <input
                                                  id={
                                                    methods.register
                                                      ? methods.register.name
                                                      : ""
                                                  }
                                                  {...getInputProps()}
                                                />
                                                <span>
                                                  <FaFileUpload size={40} />
                                                </span>
                                                <p style={{ padding: "4px" }}>
                                                  File Upload
                                                </p>
                                              </div>
                                            );
                                          }}
                                        </Dropzone>
                                      </>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                      {result.status === "pending" && (
                                        <Box sx={{ width: "130px" }}>
                                          <LinearProgress />
                                        </Box>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              }
              <div className="f-a-btn mt-2 text-center">
                <button type="submit" className="btn btn-info text-light ">
                  Submit
                </button>
              </div>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure want to delete document ?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Warning! Once deleted cannot revert back .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                className="btn btn-info"
                onClick={() => {
                  methods.setValue("proof_of_document.response", "Yes");
                  setOpen(false);
                }}
              >
                No
              </button>
              <button
                className="btn btn-info"
                onClick={async () => {
                  //Delete logic
                  let filePath = data?.proof_of_document?.files[0];
                  let newPath = filePath.split("/");
                  deleteDocumentHandler(newPath[newPath?.length - 1]);
                }}
                autoFocus
              >
                {deleteDocResult.isLoading ? (
                  <Loader
                    type="Circles"
                    color="#FFFFFF"
                    height={20}
                    width={20}
                    radius={20}
                    timeout={3000}
                  />
                ) : (
                  "Yes"
                )}
              </button>
            </DialogActions>
          </Dialog>
        </fieldset>
      </form>
    </FormProvider>
  );
}

export default SecondaryApp;
