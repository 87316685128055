import React from 'react';
import ProofOfIdentity from '../../components/identification/identification-details/proof-of-identity/proof-of-identity';
import ProofOfBank_doc_upload from './ProofOfBank_doc_upload';
import ProofOfIdentity_doc_upload from './ProofOfIdentity_doc_upload';
import ProofOfIncome_doc_upload from './ProofOfIncome_doc_upload';
import ProofOfOtherDocDocUpload from './ProofOfOtherIncome_doc_upload';
import ProofOfOtherIncomeDocUpload from './ProofOfOtherIncome_doc_upload';
import ProofOfDoc_doc_upload from './ProofOfOther_doc_upload';
import ProofOfSSN from './ProofOfSSN';

const Identification_doc_upload = () => {
  
    return (
        <div>
             <ProofOfIdentity_doc_upload />
             <br/>
             <ProofOfSSN />
             <br/>
             <ProofOfIncome_doc_upload />
             <br/>
              <ProofOfOtherIncomeDocUpload />
             <br/>
             <ProofOfBank_doc_upload />
             <br/>
             <ProofOfDoc_doc_upload />
        </div>
    );
};

export default Identification_doc_upload;