import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import GoogleAutoComplete from "../../components/common/googleAutocomplete/googleAutocomplete";
import InputCurrencyField from "../../components/registration/Inputs/InputCurrencyField";
import InputDateField from "../../components/registration/Inputs/InputDateField";
import InputField from "../../components/registration/Inputs/InputField";
import SelectField from "../../components/registration/Inputs/SelectField";
import * as yup from "yup";
import {
  useGetUpdatedDesiredAddressQuery,
  useUpdateDesiredAddessMutation,
} from "../../services/identificationApi";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";

function UpdateDesireAddress() {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );

  const { data, isLoading, isError } = useGetUpdatedDesiredAddressQuery(trn_id);
  console.log("Check Updated Desired Address Data", data);

  const [updateDesiredAddess] = useUpdateDesiredAddessMutation();

  const schema = yup.object().shape({
    address: yup.string().required("required").matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name").matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Only Alphabet and Numbers are Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
    city: yup.string().required("required").matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name").matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Only Alphabet and Numbers are Allowed").matches(/^$|^\S+.*/g, "Enter a valid value"),
    state: yup.object({
      value: yup.string().required("required"),
      label: yup.string(),
    }),
    unitNo: yup
      .number()
      .typeError("required")
      .nullable()
      .min(1),
      // .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed"),
    rent: yup
      .string()
      .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
      .min(1,'Greater than 0 value required')
      .nullable(true),
    desiredMoveInDate: yup.date().typeError("required").required("required"),
  });

  const methods = useForm({
    defaultValues: {
      address: "",
      city: "",
      state: "",
      unitNo: "",
      rent: "",
      desiredMoveInDate: "",
    },
    resolver: yupResolver(schema),
    mode: "all",
  });
  const { register, setValue } = methods;

  const onSubmit = async (formData) => {
    console.log("Form DAta", formData);
    const response = await toast.promise(
      updateDesiredAddess({
        trn_id: trn_id,
        street_address: formData.address,
        city: formData.city,
        move_in_date: formData.desiredMoveInDate,
        state: formData.state,
        unitNo: formData.unitNo,
        desired_rent: formData.rent,
      }),
      {
        pending: "Saving In Progress",
        success: "Address updated 👌",
        error: "Saving failed 🤯",
      }
    );
    if (response?.data?.success) {
    } else {
      toast.error("Error in update address");
    }
  };

  React.useEffect(() => {
    if (data) {
      let state = {
        value: data?.records?.applicantState[0]?.ID,
        label:
          data?.records?.applicantState[0]?.StateShortCode +
          " - " +
          data?.records?.applicantState[0]?.StateName,
      };
      let api_data = data?.records?.result?.recordset[0];
      setValue("address", api_data.DesiredPropertyAddress);
      setValue("city", api_data.City);
      setValue("state", state);
      setValue("rent", api_data.DesiredRent);
      setValue("desiredMoveInDate", api_data.DesiredMoveInDate);
      setValue("unitNo", api_data.DesiredUnitNumber);
    }
  }, [data, setValue]);
  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  else
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => onSubmit(data))}>
          <div className="position-relative" style={{ height: "70vh" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-8 offset-md-2 ">
                  <div
                    className="form-w-block white-box mr-b30"
                    id="desired"
                    onFocus={() => {
                      //   setNav(2);
                    }}
                  >
                    <div className="row form-inputs">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group cus-input-group mr-b30">
                          {/* <GoogleAutoComplete
                            groupFieldName={{
                              city: `city`,
                              state: `state`,
                              state_data: data ? data?.state : [],
                            }}
                            register={register("address")}
                            label="Desired Address"
                          /> */}
                          <InputField
                            label="Address"
                            register={register("address")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group cus-input-group mr-b30">
                          <InputField
                            label="City"
                            register={register("city")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group cus-input-group mr-b15">
                          <SelectField
                            defaultValues
                            options={data ? data?.state : []}
                            //   options={[]}
                            label="State"
                            register={register("state")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group cus-input-group mr-b30">
                          <InputField
                            type="number"
                            notRequired={true}
                            label="Unit No."
                            register={register("unitNo")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group cus-input-group mr-b30">
                          <InputCurrencyField
                            label="Desired Rent"
                            suffix="$"
                            register={register("rent")}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group cus-input-group mr-b30">
                          <InputDateField
                            minDate={new Date()}
                            type={"date"}
                            label="Desired Move-in Date"
                            placeholder="Enter Date"
                            register={register("desiredMoveInDate")}
                          />
                        </div>
                      </div>
                      <div className="f-a-btn text-center">
                        <button
                          type="submit"
                          className="btn btn-info text-light "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </form>
      </FormProvider>
    );
}

export default UpdateDesireAddress;
