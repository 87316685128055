import React from "react";
import { BiTrash } from "react-icons/bi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useDeleteDocumentMutation } from "../../../services/identificationApi";
import { AiFillWarning } from "react-icons/ai";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { protocol } from "../../../config";
import { useSelector } from "react-redux";

const Index = (props) => {
  const [deleteDocument, DeleteResult] = useDeleteDocumentMutation();
  const [open, setOpen] = React.useState(false);
  const token = useSelector(
    (state) => state.persistedReducer.loginSlice.token
  );
  return (
    <>
      <div className=" text-center" title={props?.fileObj?.file_name}>
        {props?.fileObj?.is_Duplicate === 1 ? (
          <div
            className="ssn-text-block mb-1"
            style={{ color: "rgb(102, 60, 0)" }}
          >
            <small>
              <AiFillWarning color={"#FFA117"} size={13} />
              Duplicate Payslip
            </small>
          </div>
        ) : (
          <div className="mb-1">&nbsp;</div>
        )}
        <div className={`p-1`}>
          <div className="text-center">
            {/* <BsFillFileEarmarkPdfFill
              color="#F40F02"
              size={40}
              onClick={() =>
                window.open("http://" + props.fileObj.path, "_blank").focus()
              }
            /> */}
            <img
              src={`${props?.fileObj?.path_image}?token=${token}`}
              className="img-thumbnail cursor-pointer ms-2 me-2"
              alt=""
              width={150}
              height={150}
              onClick={() =>
                window.open(protocol + props?.fileObj?.path+`?token=${token}`, "_blank").focus()
              }
            />
          </div>
          <div className="">
            <div className="ssn-text-block">
              <small className="text-black">
                {props?.fileObj?.file_name?.length > 21
                  ? props?.fileObj?.file_name.substring(0, 24) + "..."
                  : props?.fileObj?.file_name}
              </small>
            </div>
            {!DeleteResult.isUninitialized ? (
              <button
                className="btn btn-link btn-sm text-danger"
                type="button"
                disabled
              >
                Deleting...
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setOpen(true)}
                title={`Delete ${props?.fileObj?.file_name}`}
                className="btn btn-link btn-sm text-danger"
              >
                <BiTrash /> Delete
              </button>
            )}
          </div>
        </div>
        <Dialog
          open={open}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete file ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Warning! Once deleted cannot revert back .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={async () => {
                deleteDocument(props?.fileObj?.application_document_id);
                setOpen(false);
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Index;
