import React, { useState, useEffect } from "react";
import ESignature from "../../../common/eSignature/eSignature";
import StripeButton from "../../../common/stripeButton/stripeButton";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { get, useController, useFormContext } from "react-hook-form";
import InputField from "../../../registration/Inputs/InputField";
import { FiEdit2 } from "react-icons/fi";
import { useGetIdentificationDataQuery } from "../../../../services/identificationApi";
import { useSelector } from "react-redux";
import { AiOutlineCheckCircle } from "react-icons/ai";

import SelectField from "../../../registration/Inputs/SelectField";
// import InputDateField from "../../../registration/Inputs/InputDateField";
import DatePicker from "react-datepicker";
import InputDateField from "../../../registration/Inputs/InputDateField";
import InputMaskField from "../../../../components/registration/Inputs/InputMaskFieid";
import { pk } from "../../../../config";
//const PUBLIC_KEY = "pk_test_jEW1bNUBBo8Us5pZhICE2you00sGXyEU8Q";
// const PUBLIC_KEY = "pk_test_9ZkpCpGvA66YFwajWDCFQNoX";
const PUBLIC_KEY = pk;

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const PaymentSignature = (props) => {
  console.log("check props for payment signature", props);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const getIdentificationData = useGetIdentificationDataQuery(trn_id);
  const [identificationData, setIdentificationData] = useState(
    getIdentificationData?.data
  );
  const [signatureFlag, setSignatureFlag] = useState(false);
  const [signatureImage, setSignatureImage] = useState("");
  const [signatureFont, setSignatureFont] = useState("");
  const [signatureText, setSignatureText] = useState("");
  const [signatureType, setSignatureType] = useState("pad");
  const [isEmptySignature, setIsEmptySignature] = useState(false);
  const [signatureEnd, setSignatureEnd] = useState(true);
  const [signatureTyped, setSignatureTyped] = useState(true);

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    control,
    trigger,

    formState: { errors },
  } = useFormContext();

  const watchApplicant = watch("copyapplicant");
  let addressData = {
    billingAddress: watch("billingAddress"),
    city: watch("city"),
    state: watch("state"),
    zipCode: watch("zipCode"),
    name: identificationData?.identification_data.first_name + " " + identificationData?.identification_data.last_name,
    email: identificationData?.applicant.Email,
    phone: identificationData?.identification_data.mobile[0].number,
    trn_id: trn_id,
    file: signatureImage,
    document_id: "8",
  };

  // useEffect(() => {
  //   console.log("Check IDData", identificationData);
  //   if (watchApplicant) {
  //     setValue("cname", identificationData?.identification_data?.first_name);
  //   } else {
  //     setValue("cname", "");
  //   }
  // }, [identificationData, setValue, watchApplicant]);

  const watchApplicantBillingAddress = watch("copybillingaddress");
  const watchApplicantName = watch("copyapplicantname");

  useEffect(() => {
    console.log("watchApplicantBillingAddress", watchApplicantBillingAddress);
    if (watchApplicantBillingAddress) {
      setValue(
        "billingAddress",
        getIdentificationData?.data?.address[0]?.street_address,
        { shouldValidate: true }
      );
      setValue("city", getIdentificationData?.data?.address[0]?.city, {
        shouldValidate: true,
      });
      setValue("state", getIdentificationData?.data?.address[0]?.state, {
        shouldValidate: true,
      });
      setValue("zipCode", getIdentificationData?.data?.address[0]?.zipcode, {
        shouldValidate: true,
      });
    } else {
      setValue("billingAddress", "");
      setValue("city", "");
      setValue("state", "");
      setValue("zipCode", "");
    }
  }, [
    watchApplicantBillingAddress,
    setValue,
    identificationData?.address,
    getIdentificationData?.data?.address,
  ]);

  useEffect(() => {
    console.log(
      "PS:98",
      identificationData,
      getIdentificationData,
      getIdentificationData?.data?.identificationData?.identification_data
        ?.first_name
    );
    if (watchApplicantName) {
      setValue(
        "cname",
        getIdentificationData?.data?.identification_data?.first_name +
          " " +
          getIdentificationData?.data?.identification_data?.last_name,
        { shouldValidate: true }
      );
    } else {
      setValue("cname", "", { shouldValidate: true });
    }
  }, [
    watchApplicantName,
    identificationData?.identification_data?.first_name,
    identificationData?.identification_data?.last_name,
    setValue,
    identificationData,
    getIdentificationData,
  ]);

  // useEffect(() => {
  //   const subscribe = watch((value, { name, type }) => {
  //     console.log(
  //       "Check value, name, type PAYMENT line:105",
  //       value,
  //       name,
  //       type
  //     );
  //     // if (type === "change" && name === `ssn_data.isUS_citizen`) {
  //     //   trigger("ssn_data.SSNNumber");
  //     // }
  //     if (
  //       (type === "change" && name === "billingAddress") ||
  //       (type === "change" && name === "state") ||
  //       (type === "change" && name === "city") ||
  //       (type === "change" && name === "zipCode") ||
  //       (type === "change" && name === "cname")
  //     ) {
  //       trigger("billingAddress");
  //       trigger("state");
  //       trigger("city");
  //       trigger("zipCode");
  //       trigger("cname");
  //     }
  //   });
  //   return () => subscribe.unsubscribe;
  // }, [watch, trigger]);
  // let values = getValues("cvv");

  // useEffect(() => {
  //   const subscribe = watch((value, { name, type }) => {
  //     if (name == "copybillingaddress" && type == "change") {
  //       trigger("billingAddress");
  //       trigger("city");
  //       trigger("state");
  //       trigger("zipCode");
  //     }
  //   });
  //   return () => subscribe.unsubscribe();
  // }, [watch, trigger]);

  return (
    <div className="site-cus-tab" id="ps">
      <div
        id="psCollapse"
        className={`"ps-wrap white-box  pd20"`}
      >
        <div className="ps-body">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="blue-box pd15 mr-b50">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-8 col-lg-8 ">
                        <div className="blue-box pd30 mr-b50">
                          <div className="cus-check mr-b15">
                            <input
                              type="checkbox"
                              id="copyapplicantname"
                              name="copyapplicantname"
                              {...register("copyapplicantname")}
                            />
                            <label
                              htmlFor="copyapplicantname"
                              className="mr-b0 mx-auto align-items-center"
                            >
                              <span className="mr-r10"></span>
                              Copy Applicant Name
                            </label>
                          </div>
                          <div style={{ height: "10px" }} />
                          <InputField
                            label="Enter Card Holder Name here"
                            register={register("cname")}
                          />
                          <div className="mb-2" />
                          <label
                            htmlFor={"cardNumber"}
                            className={`form-label`}
                          >
                            Enter Card Number <span className="red">*</span>
                          </label>
                          <Elements
                            stripe={stripeTestPromise}
                            className="stripe-btn"
                          >
                            <StripeButton
                              applicationFee={props?.applicantFee}
                              signatureImage={signatureImage}
                              addressData={addressData}
                              callSubmit={props.callSubmit}
                              setIsEmptySignature={setIsEmptySignature}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 ">
                    <div className="ad-b-check mr-b30">
                      <div className="cus-check mr-b15">
                        <input
                          type="checkbox"
                          id="copybillingaddress"
                          name="copybillingaddress"
                          {...register("copybillingaddress")}
                        />
                        <label
                          htmlFor="copybillingaddress"
                          className="mr-b0 mx-auto d-flex align-items-center"
                        >
                          <span className="mr-r10"></span>
                          Use current address for billing address
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="w-100"></div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <InputField
                      label="Billing Address"
                      register={register("billingAddress")}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <InputField label="City" register={register("city")} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    {/* <InputField label="State" register={register("state")} /> */}
                    <SelectField
                      options={getIdentificationData?.data?.states?.map(
                        (item, index) => {
                          return {
                            value: item.ID || index,
                            label: item.StateShortCode + " - " + item.StateName,
                          };
                        }
                      )}
                      label="Select State"
                      register={register("state")}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 mt-2">
                    {/* <InputMaskField
                      maxLength={5}
                      inputType="ZIP"
                      label="Zip Code"
                      register={register("zipCode")}
                      mask={"99999"}
                    /> */}
                    <InputField
                      label="Zip Code"
                      type={"tel"}
                      maxLength={10}
                      minLength={5}
                      register={register("zipCode")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-12">
              {signatureType === "pad" && signatureImage !== "" ? (
                <div className="d-flex">
                  {/* <h5 className="align-self-end">
                    E-Signature: <span className="red">*</span>
                  </h5> */}
                  {/* <span className="ml-4 mb-1 align-self-end text-center">
                    {identificationData?.identification_data?.first_name}
                    {identificationData?.identification_data?.last_name}
                  </span> */}
                  <img
                    className="sign-block"
                    src={signatureImage}
                    alt="signaturePadImage"
                  />
                </div>
              ) : (
                signatureImage !== "" &&
                signatureType === "text" && (
                  <div className="col-sm-6 col-md-4 col-lg-4 ">
                    {/* <h5>
                      E-Signature: <span className="red">*</span>
                    </h5> */}
                    <img
                      className="eSignTextImage "
                      src={signatureImage}
                      alt="signaturePadImage"
                    />
                  </div>
                )
              )}
              <div style={{ height: "10px" }} />
              {/* <div className="d-flex">
                <h5 className="align-self-end">
                  E-Signature: <span className="red">*</span>
                </h5>
                <span className="ml-4 mb-1 align-self-end text-center">
                  {identificationData.identification_data?.first_name}{" "}
                  {identificationData.identification_data?.last_name}
                </span>
              </div> */}
              <div className="row">
                <div className="col-sm-8 col-md-8 col-lg-8">
                  <div className="d-flex">
                    <h5 className="  ">
                      E-Signature: <span className="red">*</span>
                    </h5>
                    <span className="ms-2">
                      {identificationData?.identification_data?.first_name}{" "}
                      {identificationData?.identification_data?.last_name}
                    </span>
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4">
                  {signatureFlag === false && (
                    <button
                      type="button"
                      className="type-btn"
                      data-toggle="modal"
                      data-target="#signModal"
                      onClick={() => {
                        setSignatureFont("");
                        setSignatureImage("");
                        setSignatureText("");
                        setSignatureFlag(true);
                        setSignatureTyped(true);
                        setSignatureEnd(true);
                      }}
                    >
                      <FiEdit2 />
                    </button>
                  )}
                  <br />
                  <small>Click Here to Sign</small>
                  {isEmptySignature && (
                    <small className="red">Please Enter Signature</small>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 ">
              <div className="t-btn text-right ">
                {signatureFlag && (
                  <ESignature
                    cancelOperation={(data) => setSignatureFlag(false)}
                    submit={(data) => {
                      console.log("Check TYPE", data);
                      setSignatureImage(data);
                      setSignatureFlag(false);
                    }}
                    signatureText={signatureText}
                    setSignatureText={setSignatureText}
                    signatureEnd={signatureEnd}
                    setSignatureEnd={setSignatureEnd}
                    signatureTyped={signatureTyped}
                    setSignatureTyped={setSignatureTyped}
                    signatureFont={signatureFont}
                    setSignatureFont={setSignatureFont}
                    signatureType={signatureType}
                    setSignatureType={setSignatureType}
                    setSignatureImage={setSignatureImage}
                    applicantFirstName={
                      identificationData?.identification_data?.first_name
                    }
                    applicantMiddleName={
                      identificationData?.identification_data?.middle_name
                    }
                    applicantLastName={
                      identificationData?.identification_data?.last_name
                    }
                    applicantName={
                      identificationData?.identification_data?.first_name +
                      " " +
                      identificationData?.identification_data?.last_name
                    }
                    applicantNameReverse={
                      identificationData?.identification_data?.last_name +
                      " " +
                      identificationData?.identification_data?.first_name
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSignature;
