/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logoFile from "../../../assets/images/logo.png";
import { AiOutlineLogout } from "react-icons/ai";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout, token } from "../../../store/registration/logindSlice";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { logout_url } from "../../../config";

const SuperHeader = (props) => {
  const [logoutShow, setLogoutShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const google = window.google;

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      "google_translate_element"
    );
  };

  return (
    <>
      <div></div>
      <section className="top-blue-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8 col-sm-6">
              <div className="w-r-logo">
                <img src={logoFile} alt="" title="" />
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div
                className="t-w-appid text-right d-flex"
                style={{ justifyContent: "end" }}
              >
                {location.pathname == "/instruction" ||
                location.pathname == "/identification" ||
                location.pathname == "/identification/" ||
                location.pathname == "/identification/applicantDetail" ||
                location.pathname == "/identification/incomeDetail" ||
                location.pathname == "/identification/payment" ||
                location.pathname == "/viewconsent" ||
                location.pathname == "/view" ||
                location.pathname == "/success" ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div style={{ paddingRight: "30px" }}>
                      <div id="google_translate_element"> </div>
                    </div>
                    <div>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_400:74)">
                            <path
                              d="M5.9541 20.426C5.9541 18.8227 6.59103 17.285 7.72478 16.1512C8.85853 15.0175 10.3962 14.3806 11.9996 14.3806C13.6029 14.3806 15.1406 15.0175 16.2744 16.1512C17.4081 17.285 18.0451 18.8227 18.0451 20.426H16.5337C16.5337 19.2235 16.056 18.0702 15.2057 17.2199C14.3554 16.3696 13.2021 15.8919 11.9996 15.8919C10.7971 15.8919 9.64379 16.3696 8.79348 17.2199C7.94317 18.0702 7.46547 19.2235 7.46547 20.426H5.9541ZM11.9996 13.6249C9.49449 13.6249 7.46547 11.5958 7.46547 9.09075C7.46547 6.58566 9.49449 4.55664 11.9996 4.55664C14.5047 4.55664 16.5337 6.58566 16.5337 9.09075C16.5337 11.5958 14.5047 13.6249 11.9996 13.6249ZM11.9996 12.1135C13.6696 12.1135 15.0223 10.7608 15.0223 9.09075C15.0223 7.42069 13.6696 6.06801 11.9996 6.06801C10.3295 6.06801 8.97684 7.42069 8.97684 9.09075C8.97684 10.7608 10.3295 12.1135 11.9996 12.1135Z"
                              fill="white"
                            />
                            <circle
                              cx="12"
                              cy="12.9912"
                              r="11.5"
                              stroke="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_400:74">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 0.991211)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            dispatch(logout(true));
                            setAnchorEl(null);
                            window.location.replace(logout_url);
                          }}
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuperHeader;
