import { createSlice } from "@reduxjs/toolkit";

const initialState = null; 
// {
//   Agents: [],
//   City: null,
//   DesiredMoveInDate: null,
//   DesiredPropertyAddress: null,
//   DesiredRent: null,
//   DesiredUnitNumber: null,
//   Email_check: false,
//   HearAboutUs: { HearAboutUs: null },
//   LeadSource: [],
//   State: null,
//   States: [],
//   applicant_id: 0,
//   application_number: 0,
//   email: null,
//   first_name: null,
//   floors: [],
//   last_name: null,
//   middle_name: null,
//   mobile: null,
//   property: null,
//   selected_agent: null,
//   subrciber_logo: null,
//   subscriber_id: null,
//   subscriber_name: null,
//   units: []
// };

const registertionSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    updateOnceOnLoad: (state, action) => { //registration form slice
      return state=action.payload;
    }
  },
});

export const { updateOnChange, updateOnceOnLoad } = registertionSlice.actions;

export default registertionSlice.reducer;
