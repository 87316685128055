/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { useSelector } from "react-redux";
import {
  useGetIncomeBankingQuery,
  useGetSectionStatusQuery,
} from "../../services/identificationApi";
import Paystub_Source from "./Paystub_Source";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";

const ProofOfBank_doc_upload = () => {
  const [bankStatementInformation, setBankStatementInformation] =
    React.useState(false);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { isLoading, isSuccess, isError, data } =
    useGetIncomeBankingQuery(trn_id);
  console.log("BANKPROOF:15", data);
  return (
    <>
      {true && (
        <div className="ei-wrap white-box">
          <div className="ei-body">
            <div className="row">
              <div className="col-12">
                <h6>Banking Information</h6>
              </div>
            </div>
            <div className="row">
              <>
                {data?.banking_details?.banking.map((field, index) => (
                  <Paystub_Source
                    onlyPdf={true}
                    key={field.id}
                    field={field}
                    documentId={"6"}
                    title={"Bank Statement"}
                    index={index}
                    setDialogAction={setBankStatementInformation}
                    warningText={
                      "IMPORTANT! PDF bank statements downloaded directly from bank account website should be uploaded here. Pictures or scanned images of documents should not be uploaded."
                    }
                  />
                ))}
                {data?.banking_details?.banking?.length === 0 && (
                  <Paystub_Source
                    documentId={"6"}
                    title={"Bank Statement"}
                    setDialogAction={setBankStatementInformation}
                  />
                )}
              </>
            </div>
            <Dialog
              open={bankStatementInformation}
              onClose={() => {
                setBankStatementInformation(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <strong>IMPORTANT!</strong> If you did not upload original
                  PDF’s of your earnings statements, are self-employed, or
                  indicated any non-employment income, then for verification
                  purposes, you will need to upload PDF’s of your 2 most recent
                  personal bank statements downloaded directly from your online
                  bank account website. Please do NOT upload scanned images,
                  redacted documents, or screen shots of documents, and please
                  do NOT “print to PDF”, “save as” or modify these documents in
                  any way, as these documents cannot be verified by our system.
                  If you are self-employed, PDF’s of your 2 most recent personal
                  bank statements are required. If you have business bank
                  statements, you can include them, but personal bank statements
                  are required to verify self-employment income.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    setBankStatementInformation(false);
                  }}
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </>
  );
};

export default ProofOfBank_doc_upload;
