import React from "react";
import { useGetMasterRegistrationDataQuery } from "../../services/registrationApi";
import { useSelector } from "react-redux";
import { protocol } from "../../config";

const Header = () => {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const { data, isLoading, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });
  console.log("Check Master Registration DAta", data);

  return (
    <>
      {!isLoading && isSuccess && (
        <section className="top-white-row">
          <div className="container">
            <div className="d-flex align-items-center">
              <div style={{ flex: 0.6 }}>
                <img
                  src={`${data?.records?.subrciber_logo}`}
                  className="subscriber-logo"
                  alt={data.records.subscriber_name}
                  title={data.records.subscriber_name}
                />
                <small className="subscriber-name">
                  {data.records.subscriber_name}
                </small>
              </div>
              <div style={{ flex: 0.4 }}>
                <div className="d-flex justify-content-end align-items-center h-100">
                  <small className="applicant-id">
                    Application ID:{" "}
                    <small className="applicant-id">
                      {data.records.application_number}
                    </small>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Header;
