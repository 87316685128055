import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import store,{persister} from './store';
import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/dist/css/bootstrap.css';

import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
// import './assets/css/index.scss';
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
    <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
