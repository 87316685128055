import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import {
  Controller,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";

const RadioInputField = (props) => {
  const { register, setValue, getValues } = useFormContext();
  //const selectedValue = getValues(props?.name) ; //|| "Other";

  return (
    <>
      {props.options.map((item, index) => {
        return (
          <div key={index} className="form-check">
            <input
            //  checked={item?.value == selectedValue}
              className="form-check-input"
              {...register(props.name)}
              value={item.value}
              type="radio"
              name={props.name}
              id={`radioBtn${index}`}
            />
            <label className="form-check-label" htmlFor={`radioBtn${index}`}>
              {item?.label || item.value}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default RadioInputField;
