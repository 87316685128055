import React from "react";
import { BiTrash } from "react-icons/bi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import UploadFile from "../../components/common/uploadFile/uploadFile";
import {
  useDeleteDocumentMutation,
  useGetIdentificationDataQuery,
} from "../../services/identificationApi";
import { RiInformationLine } from "react-icons/ri";
import {
  AccordionDetails,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Alert,
} from "@mui/material";
import { protocol } from "../../config";

const ProofOfSSN = () => {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const { isLoading, isSuccess, isError, data } =
    useGetIdentificationDataQuery(trn_id);
  const [deleteDocument, result] = useDeleteDocumentMutation();
  const [ssnInformation, setSnnInformation] = React.useState(false);
  const token = useSelector(
    (state) => state.persistedReducer.loginSlice.token
  );
  const ssn_data = data?.ssn_data;
  console.log("ssn_data:16", ssn_data);
  return (
    <div>
      <div className="ei-wrap white-box">
        <div className="ei-body">
          <div className="row">
            <div className="col-12">
              <h6>Social Security Number</h6>
            </div>
          </div>

          {/*  */}
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="ssn-up-b-box">
                <div className="align-items-center pb-5">
                  <div className="ssn-text-block">
                    <h4>
                      Proof of SSN
                      <RiInformationLine
                        className="ms-2 cursor-pointer"
                        fill="#D3AF51"
                        size={20}
                        onClick={() => {
                          setSnnInformation(true);
                        }}
                      />
                      {/* <button type="button" className="info-btn">
                        <img src="images/info-icon.png" alt="" />
                      </button> */}
                    </h4>
                    <h5>(SSN card or un-redacted W2)</h5>
                    <small className="highlight-text-background">
                      Click the (i) button above for details
                    </small>
                  </div>
                  {ssn_data.file && (
                    <>
                      <div className="d-flex flex-column pt-4 justify-content-center align-items-center">
                        <img
                          src={`${ssn_data.file_image}?token=${token}`}
                          className="img-thumbnail cursor-pointer"
                          alt=""
                          width={150}
                          height={150}
                          onClick={() =>
                            window
                              .open(
                                `${ssn_data.file_image}?token=${token}`,
                                "_blank"
                              )
                              .focus()
                          }
                        />

                        <button
                          type="button"
                          onClick={() => {
                            deleteDocument(ssn_data.application_document_id);
                          }}
                          title={`Delete`}
                          className="btn btn-link btn-sm text-danger"
                        >
                          <BiTrash /> Delete
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-md-6">
                  <div className="d-flex justify-content-end ">
                    <UploadFile
                      label="Upload"
                      className="mr-b10"
                      documentId={4}
                      SourceId={1}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={ssnInformation}
        onClose={() => {
          setSnnInformation(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Only upload clear, complete, straight documents.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setSnnInformation(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProofOfSSN;
