import React from 'react';
import Webcam from "react-webcam";
import { MdOutlineFlipCameraIos } from "react-icons/md";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import Camera from "@mui/icons-material/Camera";
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';
import ArrowCircleRightIcon from '@mui/icons-material/DoubleArrow';
import { Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';


const ScanMenu = () => {
  
    const navigate = useNavigate();
    let { id } = useParams();
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" >
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Docuverus
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container maxWidth="sm">
                <Paper sx={{ marginTop: '40px' }}>
                    <MenuList>
                        <MenuItem onClick={() => {
                            navigate(`/scan/${id}/camera/2`)
                        }}>
                            <ListItemIcon>
                                <ArrowCircleRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Driver's License </ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            navigate(`/scan/${id}/camera/1`)
                        }}>
                            <ListItemIcon>
                                <ArrowCircleRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Passport</ListItemText>
                            {/* <Typography variant="body2" color="text.secondary">
                                ⌘C
                            </Typography> */}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            navigate(`/scan/${id}/camera/10`)
                        }}>
                            <ListItemIcon>
                                <ArrowCircleRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>PRID</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            navigate(`/scan/${id}/camera/11`)
                        }}>
                            <ListItemIcon>
                                <ArrowCircleRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Visa</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Paper>
                <Typography variant='p'>
                    Select the types of document you would to upload*
                </Typography>
            </Container>
        </>
    );
};

export default ScanMenu;