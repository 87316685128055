import React, { useEffect, useState } from 'react';
import DBR from "../../dbr";
import { MdOutlineFlipCameraIos } from "react-icons/md";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import Camera from "@mui/icons-material/Camera";
import { Button, Container, Stack } from "@mui/material";
import Webcam from "react-webcam";
import { useNavigate, useParams } from 'react-router-dom';
import './style.css';


const BackBarcodeScan = () => {
    const [pscannerS, setPscannerS] = useState(null);
    let bDestroyed = false;
    let pScanner = null;
    let elRef = React.createRef();
    const navigate = useNavigate();
    const [videoFace, setVideFace] = useState(0);
    let { id, docId } = useParams();
    useEffect(()=>{
        const reloadCount = sessionStorage.getItem('reloadCount')||0;
        if(!reloadCount) {
          sessionStorage.setItem('reloadCount',1);
          window.location.reload();
        }
    },[])
    useEffect(() => {
        (async () => {
            try {
                let scanner = await (pScanner = pScanner || DBR.BarcodeScanner.createInstance());
                let settings = await scanner.getRuntimeSettings();
                // this is to draw frame 
                settings.region.regionMeasuredByPercentage = 1;
                settings.region.regionLeft = 5;
                settings.region.regionTop = 25;
                settings.region.regionRight = 95;
                settings.region.regionBottom = 65;
                await scanner.updateRuntimeSettings(settings);

                scanner.onFrameRead = results => {
                    // if (results.length > 0) {
                    //     let licenseInfo = parseDriverLicense(results);
                    //     console.log("🚀 ~ file: BackbarcodeUpload.js ~ line 73 ~ licenseInfo", licenseInfo)
                    // }
                };
                scanner.onUnduplicatedRead = (txt, result) => {
                    if (txt.length > 0) {
                        let licenseInfo = parseDriverLicense(txt);
                        //scanner.pause();
                        console.log("🚀 ~ file: BackbarcodeUpload.js ~ line 73 ~ licenseInfo", licenseInfo)
                        localStorage.setItem("licenseInfo",JSON.stringify(licenseInfo));
                        localStorage.setItem('raw',txt);
                        navigate(`/scan/${id}/camera_back/${docId}`);
                    }
                };

                let runtimeSettings = await scanner.getRuntimeSettings();
                runtimeSettings.barcodeFormatIds = DBR.EnumBarcodeFormat.BF_PDF417;
                runtimeSettings.LocalizationModes = [2, 8, 0, 0, 0, 0, 0, 0];
                runtimeSettings.deblurLevel = 2;
                await scanner.updateRuntimeSettings(runtimeSettings);

                elRef.current.appendChild(scanner.getUIElement());
                setPscannerS(pScanner);
                await scanner.open();

            } catch (ex) {
                console.error(ex);
            }
        })();
        return () => {
            (async () => {
                bDestroyed = true;
                if (pScanner) {
                    (await pScanner).destroy();
                }
            })()
        }
    }, [])

    const DLAbbrDesMap = {
        'DCA': 'Jurisdiction-specific vehicle class',
        'DBA': 'Expiry Date',
        'DCS': 'Last Name',
        'DAC': 'First Name',
        'DBD': 'Issue Date',
        'DBB': 'Birth Date',
        'DBC': 'Gender',
        'DAY': 'Eye Color',
        'DAU': 'Height',
        'DAG': 'Street',
        'DAI': 'City',
        'DAJ': 'State',
        'DAK': 'Zip',
        'DAQ': 'License Number',
        'DCF': 'Document Discriminator',
        'DCG': 'Issue Country',
        'DAH': 'Street 2',
        'DAZ': 'Hair Color',
        'DCI': 'Place of birth',
        'DCJ': 'Audit information',
        'DCK': 'Inventory Control Number',
        'DBN': 'Alias / AKA Family Name',
        'DBG': 'Alias / AKA Given Name',
        'DBS': 'Alias / AKA Suffix Name',
        'DCU': 'Name Suffix',
        'DCE': 'Physical Description Weight Range',
        'DCL': 'Race / Ethnicity',
        'DCM': 'Standard vehicle classification',
        'DCN': 'Standard endorsement code',
        'DCO': 'Standard restriction code',
        'DCP': 'Jurisdiction-specific vehicle classification description',
        'DCQ': 'Jurisdiction-specific endorsement code description',
        'DCR': 'Jurisdiction-specific restriction code description',
        'DDA': 'Compliance Type',
        'DDB': 'Card Revision Date',
        'DDC': 'HazMat Endorsement Expiration Date',
        'DDD': 'Limited Duration Document Indicator',
        'DAW': 'Weight(pounds)',
        'DAX': 'Weight(kilograms)',
        'DDH': 'Under 18 Until',
        'DDI': 'Under 19 Until',
        'DDJ': 'Under 21 Until',
        'DDK': 'Organ Donor Indicator',
        'DDL': 'Veteran Indicator',
        // old standard
        'DAA': 'Customer Full Name',
        'DAB': 'Customer Last Name',
        'DAE': 'Name Suffix',
        'DAF': 'Name Prefix',
        'DAL': 'Residence Street Address1',
        'DAM': 'Residence Street Address2',
        'DAN': 'Residence City',
        'DAO': 'Residence Jurisdiction Code',
        'DAR': 'License Classification Code',
        'DAS': 'License Restriction Code',
        'DAT': 'License Endorsements Code',
        'DAV': 'Height in CM',
        'DBE': 'Issue Timestamp',
        'DBF': 'Number of Duplicates',
        'DBH': 'Organ Donor',
        'DBI': 'Non-Resident Indicator',
        'DBJ': 'Unique Customer Identifier',
        'DBK': 'Social Security Number',
        'DBM': 'Social Security Number',
        'DCH': 'Federal Commercial Vehicle Codes',
        'DBR': 'Name Suffix',
        'PAA': 'Permit Classification Code',
        'PAB': 'Permit Expiration Date',
        'PAC': 'Permit Identifier',
        'PAD': 'Permit IssueDate',
        'PAE': 'Permit Restriction Code',
        'PAF': 'Permit Endorsement Code',
        'ZVA': 'Court Restriction Code',
        'DAD': 'Middle Name'
    };

    const parseDriverLicense = txt => {
        let lines = txt.split('\n');
        let abbrs = Object.keys(DLAbbrDesMap);
        let map = {};
        lines.forEach((line, i) => {
            let abbr;
            let content;
            if (i === 1) {
                abbr = 'DAQ';
                content = line.substring(line.indexOf(abbr) + 3);
            } else {
                abbr = line.substring(0, 3);
                content = line.substring(3).trim();
            }
            if (abbrs.includes(abbr)) {
                map[abbr] = {
                    description: DLAbbrDesMap[abbr],
                    content: content
                };
            }
        });
        return map;
    };

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton onClick={() => {
                            navigate(-1);
                        }}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Capture Back Side
                        </Typography>
                        <Button color="inherit" onClick={() => {
                            navigate(`/scan/${id}/camera_back/${docId}`)
                        }}>Skip</Button>
                    </Toolbar>
                </AppBar>
            </Box>
            <Container maxWidth="sm">
                <Box sx={{ bgcolor: '#cfe8fc', height: '91vh' }} >
                    <Stack direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}>
                        <div >
                            <div className='dbr-scan' style={{ width: '80wh', height: '70vh', background: "#cfe8fc" }} ref={elRef}>
                            </div>
                            <Typography variant="p" component="div" sx={{ flexGrow: 1, paddingLeft: "10px" }}>
                                Please capture the front of your ID in the box
                            </Typography>
                        </div>
                    </Stack>
                </Box>
            </Container>

            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, backgroundColor: 'rgba(245, 245, 245, 0.9)' }}>
                <Toolbar>
                    <IconButton onClick={async () => {
                        pScanner = pScanner || pscannerS;
                        setVideFace(!videoFace);
                        console.log("🚀 ~ file: BackbarcodeUpload.js ~ line 156 ~ <IconButtononClick={async ~ videoFace", videoFace)
                        console.log("🚀 ~ file: BackbarcodeUpload.js ~ line 151 ~ <IconButtononClick={ ~ pScanner", pScanner)
                        if (pScanner) {
                            let scanner = await pScanner;
                            console.log("🚀 ~ file: BackbarcodeUpload.js ~ line 152 ~ <IconButtononClick={ ~ scanner", pScanner)
                            let cameras = await scanner.getAllCameras();
                            let currentRes;
                            try {
                                currentRes = await scanner.setCurrentCamera(videoFace ? cameras[1].deviceId : cameras[0].deviceId);
                                pScanner = pScanner;
                                console.log("🚀 ~ file: BackbarcodeUpload.js ~ line 163 ~ <IconButtononClick={ ~ currentRes", currentRes)
                            } catch (ex) {
                                alert('Play video failed: ' + (ex.message || ex));
                            }
                        }
                    }
                    } sx={{ backgroundColor: '#fff' }} aria-label="open drawer">
                        <CameraswitchIcon />
                    </IconButton>
                    {/* <StyledFab sx={{ backgroundColor: "rgba(245, 245, 245, 0.4)" }} aria-label="add">
                        <Camera />
                    </StyledFab> */}
                    <Box sx={{ flexGrow: 1 }} />
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default BackBarcodeScan;
