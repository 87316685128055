import React, { useEffect } from "react";
import Header from "../../components/Header";
import RegistrationOfficeFormBody from "../../components/registration/RegistrationOfficeFormBody";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
import { useForm, FormProvider } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import {
  useGetMasterRegistrationDataQuery,
  useCreateUserMutation,
  registrationApi,
} from "../../services/registrationApi";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import SuperHeader from "../../components/common/superHeader/superHeader";
import { useNavigate, Link } from "react-router-dom";
import { token } from "../../store/registration/logindSlice";
import {
  trnId,
} from "../../store/registration/logindSlice";

import { updateOnceOnLoad, updateOnChange } from "../../store/registration/registrationSlice";
import debounce from "lodash.debounce";
import { JSONTree } from 'react-json-tree';
import { logout_url } from "../../config";

const Register = (props) => {

  const dispatch = useDispatch();
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const newApp = useSelector(
    (state) => state.persistedReducer.loginSlice.newApp
  );
  const url = useSelector(
    (state) => state.persistedReducer.loginSlice.urlGeneratedAts
  );
  const { data, isLoading, isSuccess } = useGetMasterRegistrationDataQuery({
    trn_id,
    url,
  });

  
  const applicationType = useSelector(
    (state) => state.persistedReducer.loginSlice.applicationType
  );

  const formDataStore = useSelector(
    (state) => state.persistedReducer.register
  );

  let navigate = useNavigate();

  const [createUser, response] = useCreateUserMutation();

  //yup.string().isAddressRequired(),
  yup.addMethod(yup.string, "isDynamicRequiredString", function () {
    if (applicationType === "Office" || applicationType === "Agent") return this.required("required").typeError('required').matches(/^$|^\S+.*/g, "Enter a valid value")
    .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid Value");
    return this.notRequired().nullable();
  });

  yup.addMethod(yup.number, "isDynamicRequiredNumber", function () {
    if (applicationType === "Office" || applicationType === "Agent") return this.required("required");
    return this.notRequired();
  });
  yup.addMethod(yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {

    });
  });

  yup.addMethod(yup.date, "isDynamicRequiredDate", function () {
    if (applicationType !== "Office" || applicationType !== "Agent") return this.required("required");
    return this.notRequired();
  });


  yup.addMethod(yup.array, "uniquePhone", function (message) {
    return this.test("unique", message, function (data) {
      const list = data;
      let isUnique = true;
      let row = list.length;
      for (let i = 0; i < row; i++) {
        for (let j = i + 1; j < row; j++) {
          if (JSON.stringify(list[i]) === JSON.stringify(list[j])) {
            isUnique = false;
          }
        }
      }
      if (isUnique) {
        return true;
      }
      return false;
    });
  });

  const schema = yup.object().shape({
    fname: yup
      .string()
      .required("required")
      .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name")
      .matches(/^$|^\S+.*/g, "Enter a valid name")
      .min(2, "minimum 2 characters is required")
      .typeError('required'),
    lname: yup
      .string()
      .required("required")
      .typeError('required')
      .min(2, "minimum 2 characters is required")
      .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name")
      .matches(/^$|^\S+.*/g, "Enter a valid name"),
    mname: yup.string().nullable().matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name").matches(/^$|^\S+.*/g, "Enter a valid name"),
    mobile: yup.array().of(
      yup.object().shape({
        number: yup.string().required("required").typeError("required"),
      })
    ),
    agent: yup.object({
      value: yup.string().isDynamicRequiredString(),
      label: yup.string().isDynamicRequiredString(),
    }),
    otherAgentName: yup.string().when("agent", {
      is: (agent) => {
        if (agent.value === "0") return true;
        else return false;
      },
      then: yup.string().typeError('required').required("required")
      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid value"),
    }),
    coOperatingAgentStatus: yup.bool(),
    coAgentName: yup.string().when("coOperatingAgentStatus", {
      is: true,
      then: yup.string().typeError('required').required("required")
      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name"),
    }),
    realEstateAgency: yup.string().when("coOperatingAgentStatus", {
      is: true,
      then: yup.string().typeError('required').required("required")
      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name"),
    }),
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Use 8 or more characters with a mix of letters, numbers & special characters"
      )
      .required("required"),
    reEnterPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "password must match")
      .typeError('required')
      .required("required"),
    email: yup
      .string()
      .email()
      .typeError('required')
      .required("required"),
    reEmail: yup
      .string()
      .typeError('required')
      .oneOf([yup.ref("email"), null], "email must match")
      .required("required"),
    hearAboutUs: yup
      .object({
        value: yup.string(),
        label: yup.string(),
      }) .typeError('required')
      .required("required"),
    address: yup.string().nullable().isDynamicRequiredString(),
    city: yup.string().nullable().isDynamicRequiredString()
    .matches(/^$|^\S+.*/g, "Enter a valid value")
    .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid Value"),
    state: yup.object().typeError('required').required(),
    unitNo: yup
      .number()
      .nullable(),
    rent: yup
      .string()
      .isDynamicRequiredString(),
    desiredMoveInDate: yup.date().typeError("required").isDynamicRequiredDate(),
    i_confirm: yup.bool().oneOf([true], "field must be checked"),


  });

  const onSubmit = async (fromData) => {
    // dispatch(updateOnceOnLoad(fromData))
    const formData = {
      ...fromData,
      rent: fromData?.unit?.label?.split("Rent: $")[1]?.replace(")", "")
        ? fromData?.unit?.label?.split("Rent: $")[1]?.replace(")", "")
        : fromData?.floor?.label?.split("Rent: $")[2]?.replace(")", "") ||
          fromData.rent,
      trn_id: trn_id,
      url,
    }
    
    const response = await createUser(formData);
    dispatch(trnId(response?.data?.records?.trnid));

    if (response.error) {
      toast.error(
        <div>
          {response.error?.data?.message ? (
            <span>{response.error?.data?.message}</span>
          ) : (
            <span>Something went wrong!</span>
          )}
        </div>,
        { pauseOnFocusLoss: false }
      );
    }
    if (response.data) {
      toast.success(response?.data?.message, { pauseOnFocusLoss: false });
      dispatch(token(response?.data?.records?.token));
      navigate("/instruction");
    }
  };
  /**
   *
   */

  const methods = useForm({
    defaultValues: {
      fname: "",
      mname: "",
      lname: "",
      unitNo: null,
      mobile: [{ number: "" }],
      other: [{ number: "" }],
      password: "",
      i_confirm: false,
      email: "",
      reEmail: "",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    // if (!formDataStore)
    if (data) {
      methods.setValue("fname", data?.records.first_name, {
        shouldDirty: true,
      });
      methods.setValue("mname", data?.records.middle_name, {
        shouldDirty: true,
      });
      methods.setValue("lname", data?.records.last_name, { shouldDirty: true });
      methods.setValue("email", data?.records.email, { shouldDirty: true });
      methods.setValue("reEmail", data?.records.email, { shouldDirty: true });
      methods.setValue("mobile", [{ number: data?.records.mobile }], {
        shouldDirty: true,
      });
      methods.setValue("unitNo", data?.records?.DesiredUnitNumber, {
        shouldDirty: true,
      });
      methods.setValue("rent", data?.records?.DesiredRent, {
        shouldDirty: true,
      });
      methods.setValue("address", data?.records?.DesiredPropertyAddress, {
        shouldDirty: true,
      });
      methods.setValue("city", data?.records?.city, { shouldDirty: true });
      methods.setValue("state", data?.records?.state, { shouldDirty: true });
      methods.setValue("Offices", data?.records?.Offices);
      methods.setValue("Office", data?.records.Office);
      methods.setValue("floor", data?.records?.select_floor && {
        ...data?.records?.select_floor, value: data?.records?.select_floor?.ID, label: "Floor Plan: " +
          data?.records?.select_floor?.FloorCode +  
          " (Min Rent: $" +
          data?.records?.select_floor?.MinimumRent +
          ", Max Rent: $" +
          data?.records?.select_floor?.MaximumRent+ ')'
      })
      methods.setValue("unit", data?.records?.selected_unit && { ...data?.records?.selected_unit, value: data?.records?.selected_unit?.ID, label: `Unit: ` + data?.records?.selected_unit?.UnitName + ` (Rent: $` + data?.records?.selected_unit?.MaximumRent +')'})
      methods.setValue("hearAboutUs", data?.records?.hearAboutUs, {
        shouldDirty: true,
      });
      methods.setValue(
        "desiredMoveInDate",
        data?.records?.DesiredMoveInDate,
        { shouldDirty: true }
      );
      if (applicationType === "Office" || applicationType === "Agent")
        if (data.records.selected_agent)
          methods.setValue(
            "agent",
            {
              value: data.records.selected_agent.value,
              label: data.records.selected_agent.label,
            },
            { shouldDirty: true }
          );
    }
  }, [data, methods, applicationType]);

  // useEffect(() => {
  //   if (formDataStore) {
  //     methods.reset(formDataStore)
  //   }
  // }, [formDataStore])

  const {
    register,
    handleSubmit,
    setFocus,
    control,
    watch,
    formState: { errors },
  } = methods;



  React.useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError == "mobile") {
      document.getElementById("mobile").focus();
    }
  }, [errors, setFocus]);



  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  else
    return (
      <main className="body-wrap">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SuperHeader />
            <Header></Header>
            <RegistrationOfficeFormBody copyEmailNotUnique={response?.error?.status == 409} />

            <footer className="footer-wrap fixed-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <a
                      href={logout_url+"/DataPolicy/Index"}
                      target="_blank"
                      className="ms-3"
                    >
                      Data Policy
                    </a>
                    <a
                      href={logout_url+"/TermsOfUse/Index"}
                      target="_blank"
                      className="ms-3"
                    >
                      Terms of Use
                    </a>
                  </div>
                  <div className="col-6">
                    <div className="f-a-btn text-right">
                      <button
                        type="submit"
                        className="btn btn-info align-items-center"
                      >
                        Start Application
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </form>
        </FormProvider>
        <DevTool control={methods.control} />
        <ToastContainer />
      </main>
    );
};

export default Register;
