import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUploadFileServiceMutation } from "../../services/identificationApi";
import { sideNavAction } from "../../store/identification/sideMenuSlice";
import Dropzone from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import "./style.css";
import { useForm } from "react-hook-form";
import { useUploadFileMlMutation } from "../../services/mlApi";

const MLFileUpload = (props) => {
  const [uploadFileRequest, result] = useUploadFileMlMutation();


  const onChangeFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsText(e.target.files[0]);
    let data = e.target.files[0];
    reader.onload = () => {
      if (reader.readyState === 2) {
        uploadFile(data);
      }
    };
  };

  const uploadFile = (file) => {
    props.handleCallbackFileUploadResult(null);
    props.handleCallbackFileUpload(file);
    var formData = new FormData();
    formData.append("files", file);
    formData.append("documenttype_id", props.documentId);
    uploadFileRequest(formData).then((response) => {
      if (response?.data?.success === true) {
        props.handleCallbackFileUploadResult(response);
      }
    });
  };

  return (
    <>
      <div className="App">
        <Dropzone
          multiple={false}
          onDrop={(file) => {
            for (let f of file) {
              uploadFile(f);
            }
          }}
          accept={props.onlyPdf==true?"application/pdf":"image/*,application/pdf"}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
          }) => {
            const additionalClass = isDragAccept
              ? "accept"
              : isDragReject
              ? "reject"
              : "";

            return (
              <div
                {...getRootProps({
                  className: `dropzone ${additionalClass}`,style:{backgroundColor:'#2DC3E8',color:'#000'}
                })}
              >
                <input
                  id={props?.register ? props.register.name : ""}
                  {...getInputProps()}
                />
                <span>
                  <FaFileUpload size={40} />
                </span>
                <p style={{ padding: "4px" }}>Drag 'n' drop a file here, or click to select a file</p>
              </div>
            );
          }}
        </Dropzone>
      </div>
      {result.status === "pending" && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default MLFileUpload;
