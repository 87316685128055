import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    personalErr: false,
    personalDetailsStatus: null,
    ProofOfIdentityStatus: null,
    AddressStatus: null,
    SsnStatus: null,
    ApplicantDetailStatus: false,
    ApplicantErr: false,
    ContactsStatus: false,
    FinantialDetailStatus: false,
    OtherStatus: false,
    IncomeStatus:false,
    OtherIncomeStatus:false,
    BankStatus:false,
    OtherDocStatus:false,
    IncomeErr:false,
    termsStatus:false,
    paymentStatus:false,
    paymentErr:false,
    sideNavApplicant:false,
    sideNavIncome:false,
    sideNavPayment:false,
    sideNavIdentification:false,
}
const tabsState = createSlice({
    name: 'tabsState',
    initialState,
    reducers: {
        personalDetail: (state, action) => {
            state.personalDetailsStatus = action.payload;
            state.SsnStatus = false;
            state.AddressStatus = false;
            state.ProofOfIdentityStatus = false;
        },
        personalErr: (state, action) => {
            state.personalErr = action.payload;
        },
        ProofOfIdentity: (state, action) => {
            state.ProofOfIdentityStatus = action.payload;
            state.SsnStatus = false;
            state.AddressStatus = false;
            state.personalDetailsStatus = true;
        },
        Address: (state, action) => {
            state.AddressStatus = action.payload;
            state.SsnStatus = false;
            state.personalDetailsStatus = true;
            state.ProofOfIdentityStatus = true;
        },
        Ssn: (state, action) => {
            state.SsnStatus = action.payload;
            state.personalDetailsStatus = true;
            state.ProofOfIdentityStatus = true;
            state.AddressStatus = true;
        },
        ApplicantErr: (state, action) => {
            state.ApplicantErr = action.payload;
        },
        ApplicantDetail: (state, action) => {
            state.ApplicantDetailStatus = action.payload;
            state.ContactsStatus=false;
            state.FinantialDetailStatus=false;
            state.OtherStatus=false;
        },
        Contacts: (state, action) => {
            state.ContactsStatus = action.payload;
            state.ApplicantDetailStatus=true;
            state.FinantialDetailStatus=false;
            state.OtherStatus=false;
        },
        FinantialDetail: (state, action) => {
            state.FinantialDetailStatus = action.payload;
            state.ContactsStatus=true;
            state.ApplicantDetailStatus=true;
            state.OtherStatus=false;
        },
        Other: (state, action) => {
            state.OtherStatus = action.payload;
            state.FinantialDetailStatus = true;
            state.ContactsStatus=true;
            state.ApplicantDetailStatus=true;
        },
        Income:(state, action) => {
            state.OtherDocStatus = false;
            state.BankStatus = false;
            state.OtherIncomeStatus=false;
            state.IncomeStatus=action.payload;
        },
        OtherIncome:(state, action) => {
            state.OtherDocStatus = false;
            state.BankStatus = false;
            state.OtherIncomeStatus=action.payload;
            state.IncomeStatus=true;
        },
        Bank:(state, action) => {
            state.OtherDocStatus = false;
            state.BankStatus = action.payload;
            state.OtherIncomeStatus=true;
            state.IncomeStatus=true;
        },
        OtherDoc:(state, action) => {
            state.OtherDocStatus = action.payload;
            state.BankStatus = true;
            state.OtherIncomeStatus=true;
            state.IncomeStatus=true;
        },
        IncomeErr:(state,action)=>{
            state.IncomeErr=action.payload;
        },
        Terms:(state, action) => {
            state.paymentStatus = false;
            state.termsStatus = action.payload;
        },
        Payment:(state, action) => {
            state.OtherDocStatus = action.payload;
            state.termsStatus = true;
        },
        PaymentErr:(state,action)=>{
            state.paymentErr=action.payload;
        },
        sideNavIdentificationReducer:(state,action)=>{
            state.sideNavIdentification=action.payload;
        },
        sideNavApplicantReducer:(state,action)=>{
            state.sideNavApplicant=action.payload;
        },
        sideNavIncomeReducer:(state,action)=>{
            state.sideNavIncome=action.payload;
        },
        sideNavPaymentReducer:(state,action)=>{
            state.sideNavPayment=action.payload;
        }
    }
});

export const tabsStateAction = tabsState.actions;

export default tabsState.reducer;
