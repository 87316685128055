import { createSlice } from "@reduxjs/toolkit";

const initialState = null; 

const formInputCacheSlice = createSlice({
  name: "formInputCache",
  initialState,
  reducers: {
    updateOnceOnLoad: (state, action) => { //registration form slice
      return state=action.payload;
    }
  },
});

export const { updateOnChange, updateOnceOnLoad } = formInputCacheSlice.actions;

export default formInputCacheSlice.reducer;