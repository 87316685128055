import React from "react";
import { useSelector } from "react-redux";
import FileIcon from "../../components/common/FileIcon";
import UploadFile from "../../components/common/uploadFile/uploadFile";
import { RiInformationLine } from "react-icons/ri";

const Paystub_Source = ({
  onlyPdf,
  field,
  index = 0,
  title,
  setDialogAction,
  documentId,
  paystub,
  warningText,
}) => {
  return (
    <>
      <div className="col-sm-12">
        <div className="blue-box ">
          <div className="row">
            <>
              <div className="col-sm-12">
                <div className="d-flex justify-content-between align-items-center mr-t20">
                  <div className="ssn-text-block">
                    <h4>
                      {title} {index + 1}:<span>*</span>
                      <RiInformationLine
                        className="ms-2 cursor-pointer"
                        fill="#D3AF51"
                        size={20}
                        onClick={() => setDialogAction(true)}
                      />
                    </h4>
                    <h5>(covering 4 most recent weeks)</h5>
                    <small className="highlight-text-background">
                      {warningText
                        ? warningText
                        : "IMPORTANT! PDF Earnings Statements only! Click (i) button above for full details"}
                    </small>
                  </div>
                  <div className="ssn-btn">
                    <UploadFile
                      onlyPdf={onlyPdf || false}
                      label="Upload Back"
                      className="mr-b10"
                      documentId={documentId}
                      SourceId={paystub === true ? field?.id || 0 : index + 1}
                    />
                  </div>
                </div>
              </div>
            </>
            <div className="mt-1">
              <div className="col-sm-12 col-md-12 col-lg-12 d-flex">
                {field?.files?.map((item, index) => {
                  return (
                    <div
                      key={item.application_document_id}
                      className=" text-center"
                    >
                      <div className="">
                        <FileIcon fileObj={item} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paystub_Source;
