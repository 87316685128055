import React from "react";
import { useFormContext, useFormState } from "react-hook-form";

const PropertyType = (props) => {
  console.log("Check Props PropertyType", props);
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { name } = props.register;
  const { control, watch, getValues } = useFormContext();
  const { dirtyFields, errors } = useFormState({
    control,
  });
  const error = errors[name];
  return (
    <>
      <div className="form-group cus-input-group mr-b30">
        <label className={`${error && "text-danger"}`}>
          Property Type <span className="red">*</span>
        </label>
        <div className="box-r-btn t-r-box" id="pro-option">
          <label
            htmlFor={`p1${name}`}
            className={`${error && "backgroundCRD"}`}
          >
            <input
              type="radio"
              id={`p1${name}`}
              value="1"
              name="propertyType"
              {...props.register}
            />
            <span
              className={`${
                props?.availableValue === null ? "is-invalid" : ""
              }`}
            >
              Rent
            </span>
          </label>
          <label
            htmlFor={`p2${name}`}
            className={`${error && "backgroundCRD"}`}
          >
            <input
              type="radio"
              id={`p2${name}`}
              value="2"
              name="propertyType"
              {...props.register}
            />
            <span
              className={`${
                props?.availableValue === null ? "is-invalid" : ""
              }`}
            >
              Own
            </span>
          </label>
          <label
            htmlFor={`p3${name}`}
            className={`${error && "backgroundCRD"}`}
          >
            <input
              type="radio"
              id={`p3${name}`}
              value="3"
              name="propertyType"
              {...props.register}
            />
            <span
              className={`${
                props?.availableValue === null ? "is-invalid" : ""
              }`}
            >
              Other
            </span>
          </label>
        </div>
      </div>

      <div className="invalid-feedback">{error && error.message}</div>
    </>
  );
};

export default PropertyType;
