import React, { useCallback, useState } from "react";
import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import { useFormContext } from "react-hook-form";
import EmergencyContact from "./emergency-contact/emergency-contact";
import Reference from "./reference/reference";
import SelectField from "../../../registration/Inputs/SelectField";
import { useSelector } from "react-redux";
import { BsCheckCircleFill } from "react-icons/bs";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown } from "react-icons/ai";

import {
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const Contacts = (props) => {
  const [removeReference, setRemovereference] = React.useState(false);
  const [removeEmergencyreference, setRemoveemergencyreference] =
    React.useState(false);

  const { register, watch, getValues, setValue, formState } = useFormContext();
  const useWindowWidth = useSelector(
    (state) => state.identification.sideMenuSlice
  );
  const [nextSection, setNextSection] = useState(false);
  const navigate = useNavigate();
  // highlight-starts
  const debouncedSave = useCallback(
    debounce(
      (nextValue) =>
        (async () => {
          setNextSection(await props.checkValidation("Contacts", false));
        })(),
      1000
    ),
    [] // will be created only once initially
  );
  // highlight-ends

  const checkSourceAvailableHandler = (section) => {
    if (section == "reference" && watch("reference.fields")?.length == 0) {
      setValue("reference.response", "No");
    }
    if (
      section == "emergency" &&
      watch("emergency_reference.fields")?.length == 0
    ) {
      setValue("emergency_reference.response", "No");
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      debouncedSave(name);
      if (type === "change" && name === `reference.response`) {
        if (getValues(`reference.response`) === "Yes")
          setValue("reference.fields", [
            { id: 0, first_name: "", last_name: "", email: "", mobile: null },
          ]);
      } else if (type === "change" && name === `emergency_reference.response`) {
        if (getValues(`emergency_reference.response`) === "Yes")
          setValue("emergency_reference.fields", [
            {
              id: 0,
              first_name: "",
              last_name: "",
              email: "",
              mobile: null,
              reference_copy_status: false,
            },
          ]);
      } else if (type === "change" && name === `is_co_signer.response`) {
        //is_co_signer.fields
        if (getValues(`is_co_signer.response`) === "Yes")
          setValue("is_co_signer.fields", [
            { id: 0, first_name: "", last_name: "", email: "", mobile: null },
          ]);
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch]);

  return (
    <AccordionDetails>
      <div className="contact-body">
        <div className="row">
          {watch("reference.display") === true && (
            <>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    onClick={() => {
                      if (watch("reference.fields")?.length > 0) {
                        setRemoveemergencyreference(false);
                        setRemovereference(true);
                      }
                    }}
                    disable={watch("reference.fields")?.length > 0}
                    notRequired={!getValues("reference.required")}
                    label={`${getValues(
                      `reference.labelIndex`
                    )}. Do you have any references?`}
                    register={register("reference.response")}
                  />
                </div>
              </div>
              {watch("reference.response") === "Yes" && (
                <Reference
                  register={register}
                  checkSourceAvailableHandler={checkSourceAvailableHandler}
                />
              )}
            </>
          )}
          {watch("emergency_reference.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    onClick={() => {
                      if (watch("emergency_reference.fields")?.length > 0) {
                        setRemovereference(false);
                        setRemoveemergencyreference(true);
                      }
                    }}
                    disable={watch("emergency_reference.fields")?.length > 0}
                    notRequired={!getValues("emergency_reference.required")}
                    label={`${getValues(
                      `emergency_reference.labelIndex`
                    )}. Do you have an emergency contact?`}
                    register={register("emergency_reference.response")}
                  />
                </div>
              </div>
              {watch("emergency_reference.response") === "Yes" && (
                <EmergencyContact
                  referenceList={watch(`reference.fields`)}
                  register={register}
                  checkSourceAvailableHandler={checkSourceAvailableHandler}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Dialog
        open={removeReference || removeEmergencyreference}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setRemoveemergencyreference(false);
              setRemovereference(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              if (removeReference) {
                setRemoveemergencyreference(false);
                setRemovereference(false);
                setValue("reference.fields", []);
                setValue("reference.response", "No");
              }
              if (removeEmergencyreference) {
                setRemoveemergencyreference(false);
                setRemovereference(false);
                setValue("emergency_reference.fields", []);
                setValue("emergency_reference.response", "No");
              }
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
      {nextSection && (
        <Button
          onClick={() => {
            navigate("#fd");
          }}
          variant="contained"
          endIcon={<AiOutlineArrowDown />}
        >
          Next Tab
        </Button>
      )}
    </AccordionDetails>
  );
};

export default Contacts;
