// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API, BASE_URL_LOCAL } from "../config";
import {
  applicationType,
  logout,
  token,
  trnId,
  urlGeneratedAts,
} from "../store/registration/logindSlice";
import { getUrlAndTransId } from "./functions";

//const BASE_URL = "https://40.71.253.227:5091";
const BASE_URL = BASE_URL_API;
// const BASE_URL = "https://api.ats.dvapply.com";

export const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const {
      persistedReducer: {
        loginSlice: { email, token,trnId },
      },
    } = getState();
    const accessToken = token;
    if (accessToken) {
      headers.set("email", email);
      headers.set("authorization", `${accessToken}`);
      headers.set("trn_id", trnId);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  console.warn("🚀 ~ file: registrationApi.js:35 ~ baseQueryWithReauth ~ args, api, extraOptions:", args, api, extraOptions)
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 406) {
    console.log("🚀 ~ file: registrationApi.js ~ line 37 ~ baseQueryWithReauth ~ result.error", result.error)
    alert(result.error.data.message);
    api.dispatch(logout(true));
    window.location.replace(BASE_URL_LOCAL + 'unauthorizedAccess?message=' + result.error.data.message);
    return;
  }
  if (result.error && result.error.status === 401) {
    // const refreshResult = await baseQuery(
    //   {
    //     url: "users/refreshToken/",
    //   },
    //   api,
    //   extraOptions
    // );
    // if (refreshResult.data) {
    //   api.dispatch(token(refreshResult.data.records.token));
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
      api.dispatch(logout(true));
    // }
  }
  return result;
};

export const registrationApi = createApi({
  reducerPath: "registrationApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    // getEmailExists: builder.query({
    //   query: (body) => ({
    //     url: `users/emailExists/${body.email}`,
    //   }),
    // }),
    loginUser: builder.mutation({
      query: (body) => ({
        url: "/users/login",
        method: "POST",
        body: body,
      }),
    }),
    forgotUser: builder.mutation({
      query: (body) => ({
        url: "/users/forgotPassword",
        method: "POST",
        body: body,
      }),
    }),
    resetUser: builder.mutation({
      query: (body) => ({
        url: "/users/resetPassword",
        method: "POST",
        body: body,
      }),
    }),
    createUser: builder.mutation({
      query: (body) => {
        console.warn("🚀 ~ file: registrationApi.js:105 ~ body:", body)
        return {
          url: "users/register",
          method: "POST",
          body: body,
        };
      },
    }),
    getMasterRegistrationData: builder.query({
      /**
       *
       * @param {trn_id,url} body
       * @returns
       */
      query: (body) => {
        return {
          url: "users/registrationpage",
          headers: body,
        };
      },
      extraOptions: { maxRetries: 0 },
      transformResponse: async (response) => {
        const state = response.records.States.map((item, index) => {
          return {
            value: item?.ID || index,
            label: item.StateShortCode + " - " + item.StateName,
          };
        });
        let hearAboutUsSelected = null;
        if (response.records?.HearAboutUs.HearAboutUs != null) {
          const hearAbout = response.records?.LeadSource.filter((item, index) => {
            if (
              response.records?.HearAboutUs?.HearAboutUs.toLowerCase() ==
              item?.LeadSource?.toLowerCase()
            ) {
              return item;
            }
          });
          hearAboutUsSelected = {
            value: hearAbout[0]?.ID,
            label: hearAbout[0].LeadSource,
          }
        }

        let userState;
        if (response?.records?.State !== null) {
          userState = response.records.States.filter((item, index) => {
            if (response?.records?.State == item.StateShortCode) {
              return item;
            }
          });
        }

        const agents = response.records.Agents.map((item, index) => {
          return {
            value: item.ASPNetUserID || index,
            label: item.FullName,
          };
        });
        let floors = response.records?.floors;
        if (floors)
          floors = response.records.floors.map((item, index) => {
            return {
              value: item.ID || index,
              label:
                "Floor Plan: " +
                item.FloorCode +
                " (Min Rent: $" +
                item.MinimumRent +
                ", Max Rent: $" +
                item.MaximumRent + ')',
            };
          });

        agents.push({ value: 0, label: "Other" });
        const lds = response.records.LeadSource.map((item, index) => {
          return {
            value: item.ID || index,
            label: item.LeadSource,
          };
        }).filter((item) => {
          return item.value !== '75'
        });

        let selected_agent = null;
        if (response.records.selected_agent)
          selected_agent = {
            label: response.records.selected_agent.FullName,
            value: response.records.selected_agent.ASPNetUserID,
            ...response.records.selected_agent,
          };

        const res = {
          ...response,
          records: {
            ...response.records,
            selected_agent: selected_agent,
            States: state,
            Agents: agents,
            LeadSource: lds,
            floors: floors,
            hearAboutUs: hearAboutUsSelected,
            Offices: response.records.Offices.map(item => { return { value: item?.ID, label: item?.OfficeName } }),
            Office: response?.records.Office && { value: response?.records?.Office.ID, label: response?.records?.Office.OfficeName },
            state:
              response?.records?.State == null
                ? null
                : userState.length > 0 && {
                  value: userState[0].ID,
                  label:
                    userState[0].StateShortCode +
                    " - " +
                    userState[0].StateName,
                },
            city: response?.records.City,
            desiredAddress: response?.records?.DesiredPropertyAddress,
            unitNo: response?.records?.DesiredUnitNumber,
            desiredRent: response?.records?.DesiredRent,
            moveInDate: response?.records?.DesiredMoveInDate,
          },
        };
        return res;
      },
    }),
    acceptTermsAndConditions: builder.mutation({
      query: (trn_id) => {
        return {
          url: "users/updateTandC",
          method: "POST",
          body: {
            trn_id: trn_id,
          },
        };
      },
    }),
  }),
});

export const {
  useGetMasterRegistrationDataQuery,
  useCreateUserMutation,
  useLoginUserMutation,
  useForgotUserMutation,
  useResetUserMutation,
  useAcceptTermsAndConditionsMutation,
} = registrationApi;
