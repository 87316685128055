import React from 'react';
import '../../assets/mobile/css/style.css';
import cloud from '../../assets/mobile/images/cloud-icon.svg';
import pic from '../../assets/mobile/images/pic-icon.svg';
import back from '../../assets/mobile/images/back-d-icon.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetIdDocumentStatusQuery } from '../../services/identificationApi';
import { token, trnId } from '../../store/registration/logindSlice';
import { useDispatch } from 'react-redux';

const ScanHome = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const { data, isLoading } = useGetIdDocumentStatusQuery(id);
    const [searchParams, setSearchParams] = useSearchParams();
    console.log(searchParams.get("token"));
    const dispatch = useDispatch();
    if (searchParams.get("token")){
        dispatch(token(searchParams.get("token")));
        dispatch(trnId(id));
    }
    // if (data.isLoading) {
    //     return
    //     <>
    //         <p>Loading...</p>
    //     </>
    // }
    return (
        <>
            <section className="screen-wrap">
                <div className="black-title blue-title">
                    <h1>Welcome to Docuverus</h1>
                </div>
                <div className="app-body">
                    <div className="ver-wrap">
                        <h3 className="page-title text-center mr-b10">Let’s verify your identity</h3>
                        <h5 className="page-sub-title text-center">Select the types of document you would to upload</h5>
                        <div className="road-map-wrap mr-t30">
                            <ul>
                                <li>
                                    <div className="map-icon">
                                        <img src={pic} className="cir-ani" alt="" title="" />
                                    </div>
                                    <div className="map-info">
                                        <h5>
                                            Take Picture of an identity <br />
                                            proof Documents
                                        </h5>
                                    </div>
                                    <div className="divaider"></div>
                                </li>
                                <li>
                                    <div className="map-icon">
                                        <img src={cloud} className="cir-ani" alt="" title="" />
                                    </div>
                                    <div className="map-info">
                                        <h5>
                                            Upload Different types<br />
                                            of documents
                                        </h5>
                                    </div>
                                    <div className="divaider"></div>
                                </li>
                                <li>
                                    <div className="map-icon">
                                        <img src={back} className="cir-ani" alt="" title="" />
                                    </div>
                                    <div className="map-info">
                                        <h5>
                                            Take a selfie<br />
                                            and verify your identity
                                        </h5>
                                    </div>
                                    <div className="divaider"></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="app-foot text-center">
                        <button type="button" onClick={() => {
                            if (data?.records?.back?.res) {
                                alert("You have aready uploaded documents");
                                return;
                            }
                            if (data?.records?.front?.res && data?.records?.back?.res) {
                                alert("You have aready uploaded documents");
                                return;
                            }
                            if (data?.records?.front?.res && data?.records?.back?.res) {
                                alert("You have aready uploaded documents");
                                return;
                            }

                            if (data?.records?.front?.res) {
                                navigate('back/'+data?.records?.front?.document_typeid);
                                return;
                            }
                            navigate('menu');
                            return;

                        }} className="blue-btn mr-b10">Start</button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ScanHome;