import React from "react";
import {
  useLocation,
  Navigate,
  Outlet,
  useSearchParams,
} from "react-router-dom";
import {
  urlGeneratedAts,
  trnId,
  applicationType,
  newApplication,
} from "./store/registration/logindSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetApplicantStatusQuery,
  useGetSectionStatusQuery,
} from "./services/identificationApi";
import Loader from "react-loader-spinner";
import { setViewOnlyFlag } from "./store/registration/fromSubmitSlice";
import { logout_url } from "./config";

const getUrlAndTransId = () => {
  const splitPath = window.location.pathname.split("/");
  splitPath.shift();
  if (splitPath[0] === "identification") {
    splitPath.shift();
  }
  let trn_id = null;
  let fullPath = null;
  if (
    (splitPath[0] === "Office" && splitPath?.length === 5) ||
    (splitPath[0] === "Unit" && splitPath?.length === 7) ||
    (splitPath[0] === "Floor" && splitPath?.length === 6) ||
    (splitPath[0] === "Property" && splitPath?.length === 5)
  ) {
    localStorage.setItem('applicationType', splitPath[0]);
    fullPath = splitPath.slice(0, splitPath?.length - 1).join("/");
    trn_id = splitPath[splitPath?.length - 1];
  } else {
    fullPath = splitPath.slice(0, splitPath?.length).join("/");
    trn_id = 0;
  }
  console.log("Check oject route", {
    url: fullPath,
    trn_id: trn_id,
    applicationType: splitPath[0],
  });
  sessionStorage.setItem('trn_id', trn_id);
  return {
    url: fullPath,
    trn_id: trn_id,
    applicationType: splitPath[0],
  };
};

const PrivateRoute = () => {
  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  let { data, isLoading, isError, isFetching } =
    useGetApplicantStatusQuery(trn_id);
  // const auth = true; // determine if authorized, from context or however you're doing it
  let location = useLocation();
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );

  if (isError) return window.location.replace(logout_url);
  if(!token){
   return window.location.replace(logout_url);
  }
  return token ? (
    (data?.records?.application_status == "Completed" || data?.records?.application_status == "Submitted") ? (
      <Navigate to="/success" />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

const PrivateRouteViewOnly = () => {
  const token = useSelector((state) => state.persistedReducer.loginSlice.token);
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  let { data, isLoading, isError, isFetching } =
    useGetApplicantStatusQuery(trn_id);

  // const auth = true; // determine if authorized, from context or however you're doing it
  let location = useLocation();
  const dispatch = useDispatch();
  dispatch(setViewOnlyFlag(true));
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
  // if (token)
  //   <Outlet />
  // else
  // return  window.location.replace(logout_url);
  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

const PrivateRouteInstructions = () => {
  //debugger;
  const token = useSelector((state) => state.persistedReducer.loginSlice.token);

  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  let { data, isLoading, isError, isFetching } =
    useGetSectionStatusQuery(trn_id);
  let location = useLocation();

  console.log("Check Application status in instruction route:Line 121", data);
  if (isLoading)
    return (
      <Loader
        className="center-screen"
        type="Circles"
        color="#00BFFF"
        height={100}
        width={100}
        radius={200}
        timeout={3000} //3 secs
      />
    );
    if(!token){
    return   window.location.replace(logout_url);
    }
  // return <>{JSON.stringify(data.accept_terms_and_condition_status === false)}{JSON.stringify(data.accept_terms_and_condition_status === false)}</>
  return token ? (
    data?.accept_terms_and_condition_status === false ? (
      <Outlet />
    ) : (
      <Navigate to="/identification" state={{ from: location }} />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

const RouteGuardLoginRegistration = () => {
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  // if (!trn_id) return <Navigate to="/unauthorizedAccess" />;
  return <Outlet />;
};

const RouteCheckGuard = () => {
  //debugger;
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  dispatch(urlGeneratedAts(getUrlAndTransId().url));
  dispatch(trnId(getUrlAndTransId().trn_id));
  dispatch(applicationType(getUrlAndTransId().applicationType));
  dispatch(
    newApplication(searchParams.get("newapplication") == "true" ? true : false)
  );
  const registrationStatus = true;

  //if (!getUrlAndTransId().trn_id) return <Navigate to="/unauthorizedAccess" />;

  return registrationStatus ? (
    <Navigate to="/registration" replace={true} />
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export {
  RouteCheckGuard,
  RouteGuardLoginRegistration,
  PrivateRouteViewOnly,
  PrivateRouteInstructions,
};
export default PrivateRoute;
