import React, { useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useSavePaymentMutation } from "../../../services/identificationApi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { sk } from "../../../config";

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: "#000",
      color: "#000",
      fontWeight: 400,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      backgroundColor: "#fff8f8",
      lineHeight: "34px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#999",
        fontSize: "16px",
      },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
    complete: {
      backgroundColor: "#fff",
    },
  },
};

const StripeButton = (props) => {
  console.log("Stripe Props:43", props);
  const navigate = useNavigate();
  const trn_id = useSelector(
    (state) => state.persistedReducer.loginSlice.trnId
  );
  const application_fee = useSelector(
    (state) => state.identification.sideMenuSlice.applicationFee
  );
  const completeSectionStatus = useSelector(
    (state) => state.identificationApi?.queries
  );
  const sectionStatus = completeSectionStatus[`getSectionStatus("${trn_id}")`];
  console.log(
    "SB:LINE:47",
    `getSectionStatus(${trn_id})`,
    completeSectionStatus[`getSectionStatus("${trn_id}")`]
  );
  const [savePayment, result] = useSavePaymentMutation();

  useEffect(() => {
    if (props.callSubmit) {
      console.log("addressData", props.addressData);
      handleSubmit();
    }
  }, [props.callSubmit]);

  const stripe = useStripe();
  //const elements = useElements({ clientSecret, appearance });
  //const clientSecret = "sk_test_p5DOAUU99bx9UlVffcRoeiF900GhX6d8tC";
  // const clientSecret = "sk_test_ebAyKD0JSQGM4GdbEfju1ij000UTMr1MSL";
   // const clientSecret = "sk_live_BubD7nMJ7OxoeB0JAImHS7Oz00VzfBmkce";
  const clientSecret = sk;
  const appearance = {
    theme: "night",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };
  const elements = useElements({ clientSecret, appearance });
  // const elements = stripe.elements({ clientSecret, appearance });

  const handleSubmit = async (event) => {
    console.log("IDE,APP,INC", sectionStatus);
    if (props.signatureImage === "" || props.signatureImage === null) {
      props.setIsEmptySignature(true);
      return setTimeout(() => {
        props.setIsEmptySignature(false);
      }, 2000);
    }
    if (
      sectionStatus.data?.identification_details_status === false ||
      sectionStatus.data?.applicant_details_status === false ||
      sectionStatus.data?.income_banking_status === false
    ) {
      if (sectionStatus.data?.identification_details_status === false) {
        toast.error("Please complete Identification Details.");
        return navigate("/identification");
      }
      if (sectionStatus.data?.applicant_details_status === false) {
        toast.error("Please complete Applicant Details.");
        return navigate("/identification/applicantDetail");
      }
      if (sectionStatus.data?.income_banking_status === false) {
        toast.error("Please complete Income & Banking.");
        return navigate("/identification/incomeDetail");
      }
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: props.addressData.name,
        email: props.addressData.email,
        phone: props.addressData.phone,
        address: {
          city: props.addressData.city,
          country: "US",
        },
      },
    });
    if (!error) {
      console.log("Stripe 23 | token generated!", paymentMethod);
      let token = await stripe.createToken(elements.getElement(CardElement));
      console.log("Check Token Stripe Line:89 Stripe Button", token);
      let tokenData = {
        ...token.token,
        card: {
          ...token.token.card,
          name: props.addressData.name,
          address_city: props.addressData.city,
          address_line1: props.addressData.billingAddress,
          address_state: props.addressData.state.label,
          address_zip: props.addressData.zipCode,
        },
      };
      console.log("tokenData stripeButton.js Line No:97", tokenData);
      let tempObj = {
        token: tokenData,
        trn_id: props.addressData.trn_id,
        file: props.addressData.file,
        document_id: props.addressData.document_id,
        amount: application_fee,
      };
      console.log("tokenData stripeButton.js Line No:97", tempObj);
      //TODO: Under token bind name and address coming under App data.
      const response = await toast.promise(savePayment(tempObj), {
        pending: "Saving",

        error: "Saving failed 🤯",
      });
      console.log("Check Payment Response", response);
      if (response?.error?.status === 400) {
        toast.error(
          "Invalid card details, please provide valid card details. Payment Failed."
        );
      }
      if (response?.data?.status === 400) {
        toast.error(
          "Invalid card details, please provide valid card details. Payment Failed."
        );
      }
      if (response?.data?.status === 200) {
        navigate("/success");
      }
    } else {
      toast.error(error.message);
    }
  };

  return (
    <form className="stripe-btn">
      <CardElement options={CARD_OPTIONS} />
      <ToastContainer />
    </form>
  );
};

export default StripeButton;
